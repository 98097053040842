interface Tags {
  [key: string]: string | number | undefined | object | boolean
}

const isError = (err: unknown): err is Error => err instanceof Error

const noticeError = (err?: Error | null, tags?: Tags) => {
  console.error(`Error in ${JSON.stringify(tags)}:`, err)
  // if (err) {
  //   if (window.newrelic) window.newrelic.noticeError(err, tags)
  // }
}

const noticeUnknownError = (err: unknown, tags?: Tags) => {
  if (isError(err)) {
    noticeError(err, tags)
  } else {
    // TODO: Improve error handling
    console.warn('Received weird error:', err, tags)
    noticeError(err as Error, stringifyObjectTags(tags))
  }
}

const stringifyObjectTags = (tags?: Tags) => {
  if (!tags) return tags
  const keys = Object.keys(tags)
  const isObject = (key: string) => typeof tags[key] === 'object'

  if (!keys.some(isObject)) return tags

  const newTags: Tags = keys.reduce((final, currentTagKey) => {
    if (isObject(currentTagKey)) final[currentTagKey] = JSON.stringify(tags[currentTagKey])
    else final[currentTagKey] = tags[currentTagKey]
    return final
  }, {})
  return newTags
}

export { noticeError, noticeUnknownError }
export type { Tags }
