import { useMemo } from 'react'
import { IFamiliesResponse } from '../../../../domain/lancamento/api/fetchByFamily'
import { useLocalStorage } from '../../../../hooks'
import { useCoreContext } from '../../../../hooks/contexts/coreContext/coreProvider'
import { useGetFamiliesQuery } from '../../../../queries/lancamento/queries'
import { FieldSelect } from '../../../Selects/FieldSelect'
import { useFamilyStore } from '../../../../store/family/useFamilyStore'

const orderByName = (array?: IFamiliesResponse[]) => {
  return array?.sort((a, b) => {
    const nameA = a?.nome?.toUpperCase()
    const nameB = b?.nome?.toUpperCase()
    if (nameA < nameB) return -1
    if (nameA > nameB) return 1
    return 0
  })
}
export const FamilySelect = ({ onClose }: { onClose?: () => void }) => {
  const { tipoUsuario: userType, setEmpresaId } = useCoreContext()
  const { setFamily } = useFamilyStore()
  const { setValue: setFamiliaId } = useLocalStorage('familia-id')
  const { families } = useGetFamiliesQuery()
  const tipoUsuario = Number(userType)
  const isAdmin = tipoUsuario === 0

  const handleFamilyChange = ({ value: familyId, label: nome }) => {
    //Se é o admin tem que limpar a empresa da familia
    if (isAdmin) setEmpresaId(null)

    setFamiliaId(familyId || '')
    setFamily({
      id: familyId,
      nome: familyId ? nome : ''
    })

    onClose?.()
  }

  const orderedFamiliesOptions = useMemo(() => {
    const orderedFamilies = orderByName(families)
    const familiesOptions: { value: string | number | null; label: string }[] = [
      {
        value: null,
        label: 'Voltar para o Vista Planejador'
      }
    ]

    orderedFamilies?.forEach((family) =>
      familiesOptions.push({
        value: family.id,
        label: family.nome
      })
    )

    console.log('ORDERED FAMILIES')

    return familiesOptions
  }, [families])

  return (
    <FieldSelect
      id="familia-select"
      placeholder="Selecione..."
      options={orderedFamiliesOptions}
      onChange={handleFamilyChange}
      size={{ lg: 12, xl: 12 }}
    />
  )
}
