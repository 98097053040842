import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { TitleModal } from './Title'
import { FormReceita } from './formReceita'
import { FormDespesa } from './formDespesa'
import { FormManualInvestment } from './FormManualInvestment'
import { FormDivida } from './formDivida'
import { useSaveTransactionsActions } from '../SaveDataTransaction/useSaveTransactionAction'
import { useTransactionContext } from '../../../../../hooks/contexts/transactionContext'
import { TABS, TTabs } from '../SaveDataTransaction/types'
import { useLocalStorage } from '../../../../../hooks'
import SkyLight from 'react-skylight'
import { ModalV2 } from '../../../../Modal/ModalV2'

export const ModalNewTransaction = ({ parentThis }) => {
  const { modal, transaction } = useTransactionContext()
  const { EditingModals } = useSaveTransactionsActions()
  const { removeValue: removeEntryValue } = useLocalStorage('lancamentoEdicao')
  const { state } = parentThis
  const item = transaction
  const selectedTab = TABS[item?.tipoOrcamento ?? 1]
  const modalRef = useRef<SkyLight>(null)
  const [activeTab, setActiveTab] = useState<TTabs>(selectedTab)

  const FormList = useMemo(
    () => ({
      [TABS[0]]: <FormReceita parentThis={parentThis} />,
      [TABS[1]]: <FormDespesa parentThis={parentThis} />,
      [TABS[2]]: <FormManualInvestment />,
      [TABS[3]]: <FormDivida />
    }),
    [parentThis]
  )

  const Title = useCallback(() => {
    if (!activeTab) return <></>
    return <TitleModal setActiveTab={setActiveTab} activeTab={activeTab} item={item} state={state} />
  }, [activeTab, item, state])

  useEffect(() => {
    if (modal?.open) {
      if (modalRef?.current && !modalRef?.current?.state?.isVisible) modalRef?.current?.show()
      return
    }

    if (!modal?.open && modalRef?.current?.state?.isVisible) {
      modalRef?.current?.hide()
    }
  }, [item, modal, modalRef])

  useEffect(() => {
    setActiveTab(selectedTab)
  }, [selectedTab])

  if (!modal?.open) {
    modalRef?.current?.hide()
    return <></>
  }

  return (
    <>
      <ModalV2
        open={modal?.open}
        ref={modalRef}
        close={() => {
          modal.close()
          removeEntryValue()
        }}
        title={<Title />}
      >
        {activeTab && FormList[activeTab]}
      </ModalV2>

      <EditingModals />
    </>
  )
}
