import { useQuery } from 'react-query'
import {
  fetchOrcamentoByFamilyIdApi,
  fetchByFamilyForTable,
  fetchOrcamentoByFamilyIdFiltro
} from '../../domain/orcamento/api/fetchByFamily'
import { SimplifiedOrcamentoWithCategory } from '../../domain/orcamento/Orcamento'
import { QueryOptions } from '../queryOptions'
import { BudgetTransactions } from '../../domain/orcamento/budgetTransactions'
import { fetchBudgetTransactions } from '../../domain/orcamento/api/fetchBudgetTransactions'
import { useEffect } from 'react'
import { noticeError } from '../../services/Monitoring'
import { IOrcamentoFamily } from '../../domain/planner/planner'
import { useCoreContext } from '../../hooks/contexts/coreContext/coreProvider'
// import { useLocalStorage } from '../../hooks'

export const cacheKey = 'orcamento'

export const useFamilyOrcamentos = (
  familyId: number
  // options?: QueryOptions<SimplifiedOrcamento[]>
) => {
  const {
    data,
    isError: isOrcamentosError,
    isLoading: isLoadingOrcamentos,
    error: orcamentoError
  } = useQuery([cacheKey, familyId], () => fetchOrcamentoByFamilyIdApi(familyId), {
    enabled: !!familyId
  })

  const { orcamentos } = data || {}

  return {
    orcamentos,
    isOrcamentosError,
    isLoadingOrcamentos,
    orcamentoError
  }
}

export const useOrcamentoByFamilyIdFiltro = ({
  familyId,
  options
}: {
  familyId: number
  options?: QueryOptions<IOrcamentoFamily[]>
}) => {
  const { authenticated } = useCoreContext()
  const {
    data,
    isError: isOrcamentosError,
    isLoading: isLoadingOrcamentos,
    error: orcamentoError,
    refetch: refetchOrcamentos
  } = useQuery([cacheKey, familyId], () => fetchOrcamentoByFamilyIdFiltro(familyId, options || {}), {
    enabled: !!familyId && authenticated
  })

  const { orcamentos, totalResults } = data || {}

  return {
    orcamentos,
    isOrcamentosError,
    isLoadingOrcamentos,
    orcamentoError,
    totalResults,
    refetchOrcamentos
  }
}

export const useFamilyOrcamentosForTable = (
  familyId: number,
  options?: QueryOptions<SimplifiedOrcamentoWithCategory[]>
) => {
  const {
    data: orcamentos,
    isError: isOrcamentosError,
    isLoading: isLoadingOrcamentos,
    error: orcamentoError
  } = useQuery<unknown, Error, SimplifiedOrcamentoWithCategory[]>(
    [cacheKey, familyId, 'table'],
    () => fetchByFamilyForTable(familyId),
    {
      ...options,
      enabled: !!familyId
    }
  )

  return {
    orcamentos,
    isOrcamentosError,
    isLoadingOrcamentos,
    orcamentoError
  }
}

export const useBudgetTransactions = (budgetId: number, options?: QueryOptions<BudgetTransactions>) => {
  const {
    data: budgetTransactions,
    isError: isBudgetTransactionsError,
    isLoading: isLoadingBudgetTransactions,
    error: budgetTransactionsError
  } = useQuery<unknown, Error, BudgetTransactions>(
    [cacheKey, budgetId, 'transactions'],
    () => fetchBudgetTransactions(budgetId),
    options
  )

  useEffect(() => {
    if (isBudgetTransactionsError) {
      console.error('Erro ao recuperar lançamentos do orçamento', budgetId, budgetTransactionsError)
      noticeError(budgetTransactionsError, { budgetId })
    }
  }, [isBudgetTransactionsError, budgetTransactionsError, budgetId])

  return {
    budgetTransactions,
    isBudgetTransactionsError,
    isLoadingBudgetTransactions,
    budgetTransactionsError
  }
}
