import { useQuery } from 'react-query'
import { fetchMeiosPagamentoByFamilyIdFiltroApi } from '../../domain/meioPagamento/api/fetchByFamily'
import { MeioPagamento } from '../../domain/meioPagamento/meioPagamento'
import { QueryOptions } from '../queryOptions'
import { useCoreContext } from '../../hooks/contexts/coreContext/coreProvider'
// import { useLocalStorage } from '../../hooks'
// import { useEffect } from 'react'

export interface UseFamilyMeiosPagamentosResponse {
  isLoadingMeios: boolean
  isErrorMeios: boolean
  meiosPagamentos: MeioPagamento[] | undefined
  meiosPagamentoError: Error | null
  refetchMeios: () => void
}

export const cacheBaseKey = 'meio-pagamento'

export const useFamilyMeiosPagamentos = (
  familyId: number,
  options?: QueryOptions<MeioPagamento[]>
): UseFamilyMeiosPagamentosResponse => {
  const { authenticated } = useCoreContext()
  // const { setValue: setStore, value } = useLocalStorage('store')
  // const store = value ? JSON.parse(value) : ''

  const {
    isLoading: isLoadingMeios,
    isError: isErrorMeios,
    data: meiosPagamentos,
    error: meiosPagamentoError,
    refetch: refetchMeios
  } = useQuery<unknown, Error, MeioPagamento[]>(
    [cacheBaseKey, familyId],
    () => fetchMeiosPagamentoByFamilyIdFiltroApi(familyId, {}),
    { ...options, enabled: !!familyId && authenticated }
  )

  // useEffect(() => {
  //   if (meiosPagamentos) {
  //     setStore(JSON.stringify({ ...store, meiosPagamentos }))
  //   }
  // }, [meiosPagamentos, setStore, store])

  return {
    isLoadingMeios,
    isErrorMeios,
    meiosPagamentos,
    meiosPagamentoError,
    refetchMeios
  }
}
