import { createTheme } from '@material-ui/core/styles'

export const muiTheme = createTheme({
  overrides: {
    MuiTableRow: {
      root: {
        '&:nth-of-type(odd)': {
          backgroundColor: '#f8f9fe'
        }
      }
    },
    // @ts-expect-error expected-error
    MUIDataTableSelectCell: {
      fixedHeaderCommon: {
        backgroundColor: 'unset'
      }
    },
    MuiTableCell: {
      root: {
        fontFamily: 'unset',
        borderTop: 'none',
        padding: '16px 10px'
      },
      footer: {
        color: '#FFF',
        fontWeight: 700
      }
    },
    MUIDataTableHeadCell: {
      fixedHeaderYAxis: {
        fontWeight: 700
      }
    },
    MuiPaper: {
      root: {
        overflow: 'hidden'
      }
    },
    MuiToolbar: {
      regular: {
        backgroundColor: '#e4eaf4'
      }
    },
    MUIDataTablePagination: {
      root: {
        '&:last-child': {
          margin: '0px 24px 0px 24px',
          padding: 0
        }
      }
    }
  }
})
