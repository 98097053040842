import { showAlert } from '../../hooks'

export const PDFDownload = (pdfUrl, fileName = 'arquivo.pdf') => {
  fetch(pdfUrl, {
    headers: {
      'Content-Type': 'application/pdf',
      'Content-Disposition': `attachment; filename=${fileName}`,
      Referer: 'https://app.meuvista.com/'
    }
  })
    .then((response) => {
      console.log({ response })
      if (!response.ok) {
        throw new Error('Erro ao baixar o arquivo.')
      }
      return response.blob() // Obtém os dados do arquivo como um blob
    })
    .then((blob) => {
      const url = window.URL.createObjectURL(blob) // Cria uma URL temporária
      const link = document.createElement('a')
      link.href = url
      link.download = fileName // Nome do arquivo
      document.body.appendChild(link)
      link.click() // Simula o clique
      document.body.removeChild(link)
      window.URL.revokeObjectURL(url) // Libera a memória usada pela URL
    })
    .catch((error) => {
      console.error('Erro ao baixar o PDF:', error)
      alert('Falha ao baixar o arquivo. Por favor, tente novamente.')
    })
}

export const PDFOpen = ({
  pdfUrl,
  onSucces,
  onError
}: {
  pdfUrl
  onSucces: () => void
  onError: () => void
}) => {
  const popUpBloqued = isPopupBlocked()
  if (popUpBloqued) {
    showAlert(
      'Atenção',
      'O pop-up foi bloqueado pelo navegador. Por favor, desbloqueie os pop-ups para este site para visualizar o arquivo PDF.',
      onError
    )
    return
  }

  window.open(pdfUrl, '_blank', 'noopener,noreferrer')
  onSucces()
}

export const isPopupBlocked = () => {
  let popup
  try {
    popup = window.open('', '_blank', 'width=100,height=100')
    if (!popup || popup.closed || typeof popup.closed === 'undefined') {
      return true
    } else {
      popup.close() // Fecha o pop-up caso tenha sido aberto com sucesso
      return false // Pop-up não bloqueado
    }
  } catch (error) {
    return true
  }
}
