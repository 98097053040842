import { useMutation } from 'react-query'
import {
  postDashboardCategoriaPrevistoRealizadoMesApi,
  postDashboardOrcamentoPeriodoByFamilyIdApi
} from '../../domain/dashboard/api'

export const cacheBaseKey = 'dashboard'

export const usePostDashboardOrcamentoPeriodoByFamilyIdMutation = () => {
  const {
    mutateAsync: postDashboardOrcamentoPeriodoByFamilyIdAsync,
    isLoading: isLoadingPostDashboardOrcamentoPeriodoByFamilyId,
    isError: isErrorPostDashboardOrcamentoPeriodoByFamilyId,
    error: postDashboardOrcamentoPeriodoByFamilyIdError,
    isSuccess: postDashboardOrcamentoPeriodoByFamilyIdSuccess,
    reset: resetPostDashboardOrcamentoPeriodoByFamilyId,
    data
  } = useMutation(postDashboardOrcamentoPeriodoByFamilyIdApi, {
    retry: false
  })

  return {
    postDashboardOrcamentoPeriodoByFamilyIdAsync,
    isLoadingPostDashboardOrcamentoPeriodoByFamilyId,
    isErrorPostDashboardOrcamentoPeriodoByFamilyId,
    postDashboardOrcamentoPeriodoByFamilyIdError,
    postDashboardOrcamentoPeriodoByFamilyIdSuccess,
    resetPostDashboardOrcamentoPeriodoByFamilyId,
    data
  }
}

export const usePostDashboardCategoriaPrevistoRealizadoMes = () => {
  const {
    mutateAsync: postDashboardCategoriaPrevistoRealizadoMesAsync,
    isLoading: isLoadingPostDashboardCategoriaPrevistoRealizadoMes,
    isError: isErrorPostDashboardCategoriaPrevistoRealizadoMes,
    error: postDashboardCategoriaPrevistoRealizadoMesError,
    isSuccess: postDashboardCategoriaPrevistoRealizadoMesSuccess,
    reset: resetPostDashboardCategoriaPrevistoRealizadoMes,
    data
  } = useMutation(postDashboardCategoriaPrevistoRealizadoMesApi, {
    retry: false
  })

  return {
    postDashboardCategoriaPrevistoRealizadoMesAsync,
    isLoadingPostDashboardCategoriaPrevistoRealizadoMes,
    isErrorPostDashboardCategoriaPrevistoRealizadoMes,
    postDashboardCategoriaPrevistoRealizadoMesError,
    postDashboardCategoriaPrevistoRealizadoMesSuccess,
    resetPostDashboardCategoriaPrevistoRealizadoMes,
    data
  }
}
