import React, { useState } from 'react'
import { useCoreContext } from '../../hooks/contexts/coreContext/coreProvider'
import { useTheme } from '../../hooks'
import * as S from './styles'
import Logo from '../../theme/logo'
import { ModalClientSelect } from './components/ModalClientSelect'
import '../../assets/css/always-visible.css'

export const SidebarV2 = ({ id, position, logo, routes }) => {
  const { tipoUsuario: userType } = useCoreContext()
  const { theme } = useTheme()
  const [collapseOpen, setCollapseOpen] = useState(false)
  const [modalSelecaoCliente, setModalSelecaoCliente] = useState(false)
  const tipoUsuario = Number(userType)

  const toggleCollapse = () => setCollapseOpen((value) => !value)

  return (
    <>
      <ModalClientSelect isOpen={modalSelecaoCliente} onClose={() => setModalSelecaoCliente(false)} />
      <S.Nav
        className={`navbar-vertical ${position} navbar-light`}
        expand="md"
        id={id}
        style={{
          overflowX: 'hidden',
          background:
            tipoUsuario <= 1
              ? theme.colors?.backgroundSidebarManager || theme.colors.secondary
              : theme.colors.primary
        }}
      >
        <S.Container>
          {/* Mobile toggle menu */}
          <button className="navbar-toggler" type="button" onClick={toggleCollapse}>
            <span className="navbar-toggler-icon" />
          </button>

          {!!logo && (
            <S.Logo
              $width={
                theme?.sizes?.web?.x !== undefined && theme?.sizes?.web?.x !== ''
                  ? theme?.sizes?.web?.x
                  : '100%'
              }
            >
              <Logo />
            </S.Logo>
          )}

          {tipoUsuario !== 2 && <S.UserTypeBanner />}

          <S.ClientSelect onClick={() => setModalSelecaoCliente(true)} />

          {/* Navigation links */}
          <S.NavigationsLinks collapseOpen={collapseOpen} toggleCollapse={toggleCollapse} routes={routes} />
        </S.Container>

        <S.Version>Versão {global.version}</S.Version>
      </S.Nav>
    </>
  )
}
