import React, { useEffect, useMemo } from 'react'
import { AlertModal } from '../../../../Modal'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormControl from '@material-ui/core/FormControl'
import FormLabel from '@material-ui/core/FormLabel'
import { formatCurrencyBRL } from '../../../../Utils'
import * as S from './styles'
import { DetailsTransactionProps } from './types'
import { getTransactionType } from '../../../../../domain/lancamento/lancamento'
import { useTransactionContext } from '../../../../../hooks/contexts/transactionContext'
import { useStore } from '../../../../../hooks/useStore'

export const DetailsDividaTransaction = ({
  open,
  onClose,
  editingTransaction = false
}: DetailsTransactionProps) => {
  const { saveTransaction, isSubmitting, isSuccess, transaction } = useTransactionContext()
  const [radioValue, setRadioValue] = React.useState<number | null>(null)
  const { isParceled: parceled, isRepeated: repeated } = getTransactionType({
    transactionType: transaction?.tipoDeParcela ?? 99
  })
  const isParceled = parceled || repeated || transaction?.editInstallments

  let title = 'Criação'
  let subtitle: string | React.ReactNode = 'Você deseja criar este lançamento:'

  if (editingTransaction) {
    title = 'Edição'
    subtitle = 'Você deseja editar este lançamento:'
  }

  const primaryButton = useMemo(
    () => ({
      text: 'Salvar',
      onClick: () =>
        saveTransaction({
          parceled: radioValue ?? 0
        }),
      disabled: isSubmitting
    }),
    [isSubmitting, radioValue, saveTransaction]
  )

  const secondaryButton = {
    text: 'Cancelar',
    onClick: onClose,
    disabled: isSubmitting
  }

  useEffect(() => {
    if (isSuccess) onClose()
  }, [isSuccess, onClose])

  const handleChange = (event) => {
    setRadioValue(Number(event.currentTarget.value))
  }

  const EditTransactionFields = () => {
    return (
      <>
        <div>&nbsp;</div>
        <div>
          <S.Subtitle>Valor total</S.Subtitle>
          <p>{formatCurrencyBRL(transaction?.valor ?? 0)}</p>
        </div>
        {(parceled || repeated) && (
          <div>
            <S.Subtitle>Valor da parcela</S.Subtitle>
            <p>{formatCurrencyBRL(transaction?.valorParcela ?? 0)}</p>
          </div>
        )}
      </>
    )
  }
  const CreateTransactionFields = () => {
    return (
      <>
        <div>
          <S.Subtitle>Valor{isParceled && ' total'}</S.Subtitle>
          <p>{formatCurrencyBRL(transaction?.valor ?? 0)}</p>
        </div>
        {isParceled && (
          <div>
            <S.Subtitle>Valor da parcela</S.Subtitle>
            <p>{formatCurrencyBRL((transaction?.valor ?? 0) / (transaction?.parcelas ?? 1))}</p>
          </div>
        )}
      </>
    )
  }

  const { findCategory, findMeioPagamento } = useStore()
  const categoryLabel = findCategory(transaction?.categoriaId)?.nome
  const meioPagamentoLabel = findMeioPagamento(transaction?.meioPagamentoId)?.nome

  return (
    <AlertModal
      onClose={onClose}
      open={open}
      primaryButton={primaryButton}
      secondaryButton={secondaryButton}
      title={`Resumo: ${title}`}
    >
      {!isParceled && <S.Title>{subtitle}</S.Title>}

      <S.Details>
        <div>
          <S.Subtitle>Dívida</S.Subtitle>
          <p>{transaction?.categoria?.nome || categoryLabel || '---'}</p>
        </div>

        <div>
          <S.Subtitle>Forma de pagamento</S.Subtitle>
          <p>{transaction?.meioPagamento?.nome || meioPagamentoLabel || '---'}</p>
        </div>

        {editingTransaction ? <EditTransactionFields /> : <CreateTransactionFields />}
      </S.Details>

      {editingTransaction && (parceled || repeated) && (
        <S.Form>
          <FormControl component="fieldset">
            <FormLabel component="legend">{subtitle}</FormLabel>
            <RadioGroup
              aria-label="transaction"
              name="transaction"
              value={radioValue}
              onChange={handleChange}
            >
              <FormControlLabel value={0} control={<Radio />} label="Todas as parcelas" />
              <FormControlLabel value={1} control={<Radio />} label="Somente esta parcela" />
              <FormControlLabel value={2} control={<Radio />} label="A partir desta parcela" />
            </RadioGroup>
          </FormControl>
        </S.Form>
      )}
    </AlertModal>
  )
}
