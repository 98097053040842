import React from 'react'
import ApexChart from 'react-apexcharts'
import axios from 'axios'
import { registerLocale } from 'react-datepicker'
import br from 'date-fns/locale/pt-BR'
import { ThemeContext } from '../../../../hooks/contexts/themeContext'
import { withContexts } from '../../../../components'

registerLocale('br', br)

class GraficoEquilibrio extends React.Component {
  static contextType = ThemeContext

  moment = require('moment')

  state = {
    series: [
      {
        name: 'Investimentos',
        data: []
      },
      {
        name: 'Gastos',
        data: []
      }
    ],
    options: {
      legend: {
        show: false
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        curve: 'smooth'
      },
      chart: {
        id: 'basic-bar'
      },
      xaxis: {
        categories: []
      }
    }
  }

  componentDidMount() {
    var $this = this
    this.setState(
      {
        familiaId: this.props.familiaId
      },
      $this.refreshData()
    )
  }

  getData = () => {
    //var url = global.server_api + "api/EquilibrioFinanceiro/familia/"+ this.state.familiaId + "/grafico";
    var url = global.server_api_new + '/EquilibrioFinanceiro/familia/' + this.state.familiaId + '/grafico'

    var filtro = {
      DataInicial: this.state.startDate,
      DataFinal: this.state.endDate
    }

    axios.post(url, filtro).then((res) => {
      var despesas = []
      var investimentos = []
      var meses = []

      var formatoMoeda = new Intl.NumberFormat([], {
        style: 'currency',
        currency: 'BRL'
      })

      if (res.data.results != null) {
        for (var i = 0; i < res.data.results[0].length; i++) {
          var despesa = res.data.results[0][i]
          var investimento = res.data.results[1][i]

          var mes = despesa.mes + '/' + despesa.ano

          despesas.push(
            despesa.total.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }).replace('R$', '')
          )
          investimentos.push(
            investimento.total
              .toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
              .replace('R$', '')
          )
          meses.push(mes)
        }
      }

      var series = [
        {
          name: 'Investimentos R$',
          data: investimentos
        },
        {
          name: 'Gastos R$',
          data: despesas
        }
      ]

      var options = {
        legend: {
          show: false
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: 'smooth'
        },
        chart: {
          id: 'basic-bar'
        },
        xaxis: {
          categories: meses
        },
        colors: [this.context.theme.colors.primary, '#fa0000']
      }
      if (despesas !== undefined && investimentos !== undefined && meses !== undefined)
        this.setState({ series: series, options: options })
    })
  }

  setStartDate = (date, callback) => {
    if (date != null) {
      date.setHours(0)
      date.setMinutes(0, 0, 0)
      this.props.setDataInicio(this.moment(date).format('YYYY-MM-DDTHH:mm:ss'))
    }

    const startDate = date

    this.setState({ startDate }, function () {
      if (callback != null) {
        callback()
      } else {
        this.getData()
      }
    })
  }

  setEndDate = (date, callback) => {
    if (date != null) {
      date.setHours(23)
      date.setMinutes(59, 59, 59)
      this.props.setDataFim(this.moment(date).format('YYYY-MM-DDTHH:mm:ss'))
    }

    const endDate = date

    this.setState({ endDate }, function () {
      if (callback != null) {
        callback()
      } else {
        this.getData()
      }
    })
  }

  refreshData = () => {
    var $this = this

    var startDate = this.props.dataInicio
    var endDate = this.props.dataFim

    this.setStartDate(startDate, function () {
      $this.setEndDate(endDate, function () {
        // console.log('RefreshData 4');
        $this.getData()
      })
    })
  }

  render() {
    return (
      <>
        <button
          id="grafico"
          onClick={() => this.refreshData()}
          style={{ position: 'fixed', left: '-5000px' }}
        />
        <ApexChart
          options={this.state.options}
          series={this.state.series}
          type="line"
          className="home-estimate-realized"
        />
      </>
    )
  }
}

export default withContexts(GraficoEquilibrio)
