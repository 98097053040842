import React, { useState } from 'react'
import { Control, Controller } from 'react-hook-form'
import { BaseFieldValues } from '../TextareaControll/types'
import * as S from './styles'

const CheckboxControl = <T extends BaseFieldValues>({
  name,
  label,
  onChange,
  control
}: {
  name: string
  label: string
  onChange?: ({ name, checked }) => void
  control: Control<T>
}) => {
  const [checked, setChecked] = useState(false)

  return (
    <Controller
      // @ts-ignore
      name={name}
      control={control}
      render={({ field }) => (
        <S.Label>
          <S.InputContainer>
            {/* @ts-ignore */}
            <input
              {...field}
              type="checkbox"
              onChange={(event) => {
                field.onChange(event)
                setChecked(event.target.checked)
                onChange?.({ name, checked: event.target.checked })
              }}
            />
            {checked ? (
              <svg className="inputIconChecked" focusable="false" viewBox="0 0 24 24" aria-hidden="true">
                <path d="M19 3H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.11 0 2-.9 2-2V5c0-1.1-.89-2-2-2zm-9 14l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z"></path>
              </svg>
            ) : (
              <svg className="inputIcon" focusable="false" viewBox="0 0 24 24" aria-hidden="true">
                <path d="M19 5v14H5V5h14m0-2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2z"></path>
              </svg>
            )}
            <S.Ripple />
          </S.InputContainer>
          {label}
        </S.Label>
      )}
    />
  )
}

export const CheckboxControlList = <T extends BaseFieldValues>({
  options,
  onChange,
  control
}: {
  options?: { label: string; value: string }[]
  disabled?: boolean
  onChange?: ({ name, checked }) => void
  control: Control<T>
}) =>
  options?.map(({ label, value }) => {
    return <CheckboxControl key={value} name={value} label={label} onChange={onChange} control={control} />
  })
