import React from 'react'
import ReduzirGastos from './components/ReduzirGastos'
import InvestirMais from './components/InvestirMais'
import Geral from './components/Geral'
import GraficoEquilibrio from './components/GraficoEquilibrio'
import Datepicker from 'react-datepicker'
import MaskedInput from 'react-maskedinput'
import { Col, Row } from 'reactstrap'
import RestoreDate from '@material-ui/icons/Restore'
import Tooltip from '@material-ui/core/Tooltip'
import { withStyles } from '@material-ui/core/styles'
import Restore3Months from '@material-ui/icons/Looks3Outlined'
import WrapperForDatePicker from '../../../theme/wrapperForDatePicker'
import { withContexts } from '../../../components'

class Equilibrio extends React.Component {
  moment = require('moment')

  constructor(props) {
    super(props)

    this.title = {
      padding: '30px 15px 30px 0px',
      display: 'flex'
    }

    this.titleHolder = {
      paddingLeft: 0
    }

    this.state = {
      currentSelect: 0,
      startDate: this.props.dataInicio,
      endDate: this.props.dataFim
    }
  }

  onSelect = (event) => {
    this.setState({ currentSelect: event.target.id })
  }

  setStartDate = (date) => {
    this.props.setStartDate(date)

    this.setState({ startDate: date }, function () {
      $('#reduzirGastos').click()
      $('#investirMais').click()
      $('#geral').click()
      $('#grafico').click()
    })
  }

  setEndDate = (date) => {
    this.props.setEndDate(date)

    this.setState({ endDate: date }, function () {
      $('#reduzirGastos').click()
      $('#investirMais').click()
      $('#geral').click()
      $('#grafico').click()
    })
  }

  gerarDatePicker = (id, defaultDate, type) => {
    var currentDate = null

    if (!defaultDate) {
      currentDate = new Date()
    } else {
      if (type === 'filtro-inicio' && this.state.startDate != null) {
        currentDate = this.state.startDate
      } else if (type === 'filtro-fim' && this.state.endDate != null) {
        currentDate = this.state.endDate
      } else {
        currentDate = defaultDate.toDate()
      }
    }

    return (
      <WrapperForDatePicker>
        <Datepicker
          locale="br"
          className="date-picker-styled"
          id={id}
          dateFormat="dd/MM/yyyy"
          selected={currentDate}
          showYearDropdown
          dateFormatCalendar="MMMM"
          scrollableYearDropdown
          yearDropdownItemNumber={5}
          customInput={<MaskedInput mask="11/11/1111" />}
          onChange={(date) => {
            id === 'data-inicial' ? this.setStartDate(date) : this.setEndDate(date)
          }}
        />
      </WrapperForDatePicker>
    )
  }

  renderTabs = (id) => {
    var tabHeader = {
      margin: '0'
    }
    var tabHeaderItem = {
      width: '33.33%',
      display: 'inline-block',
      textAlign: 'center',
      border: '1px solid #e4eaf4',
      background: '#e4eaf4',
      borderWidth: '1px 1px 0px 1px',
      borderRadius: '16px 16px 0 0',
      cursor: 'pointer',
      padding: '5px',
      lineHeight: '2rem'
    }
    var array = [
      Object.assign({}, tabHeaderItem),
      Object.assign({}, tabHeaderItem),
      Object.assign({}, tabHeaderItem)
    ]
    array[id].background = 'white'
    array[id].fontWeight = '600'

    return (
      <div style={tabHeader}>
        <div id="0" onClick={this.onSelect} style={array[0]}>
          Reduzir gastos
        </div>
        <div id="2" onClick={this.onSelect} style={array[2]}>
          Investir mais
        </div>
        <div id="1" onClick={this.onSelect} style={array[1]}>
          Geral
        </div>
      </div>
    )
  }

  renderSelected = (id) => {
    var array = [
      {
        display: 'none'
      },
      {
        display: 'none'
      },
      {
        display: 'none'
      }
    ]
    array[id].display = 'block'
    return (
      <div style={{ marginTop: '-15px' }}>
        <div style={array[0]}>
          <ReduzirGastos />
        </div>
        <div style={array[1]}>
          <Geral />
        </div>
        <div style={array[2]}>
          <InvestirMais />
        </div>
      </div>
    )
  }

  render() {
    const TooltipRestore = withStyles((theme) => ({
      tooltip: {
        backgroundColor: '#888888',
        color: 'white',
        fontSize: 12,
        fontWeight: 'bold',
        fontFamily: 'Open Sans'
      }
    }))(Tooltip)

    return (
      <div style={{ padding: '0 5%', marginTop: 20 }}>
        <section style={this.fullBody}>
          <div style={this.title}>
            <Col style={this.titleHolder} lg="4" md="6">
              <img
                src={require('../../../assets/img/theme/icone-calendario.png')}
                alt="Gestão de Orçamento"
                className="before-title-img"
              />
              <h3 className="mb-0 chart-title">Equilíbrio Financeiro</h3>
            </Col>
            <Col style={{ display: 'flex', paddingRight: 0, gap: '8px' }} lg="8" md="6">
              <label>Período </label>
              {this.gerarDatePicker('data-inicial', this.props.startDate, 'filtro-inicio', 'startDate')}
              {this.gerarDatePicker('data-final', this.props.endDate, 'filtro-fim', 'endDate')}
              <TooltipRestore
                disableFocusListener
                disableTouchListener
                title="Clique para ver apenas o orçamento do mês atual"
              >
                <RestoreDate
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    const { start, end } = monthRange(new Date())
                    this.setStartDate(start)
                    this.setEndDate(end)
                  }}
                />
              </TooltipRestore>
              <TooltipRestore
                disableFocusListener
                disableTouchListener
                title="Clique para ver o orçamento dos últimos três meses"
              >
                <Restore3Months
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    this.setStartDate(this.moment().subtract(3, 'months').startOf('month').toDate())
                    this.setEndDate(this.moment().subtract(1, 'months').endOf('month').toDate())
                  }}
                />
              </TooltipRestore>
            </Col>
          </div>
          <Row>
            <Col xl="6" lg="12" md="12" style={{ marginBottom: 40 }}>
              <div style={this.halfBody}>
                <div style={this.tabs}>
                  {this.renderTabs(this.state.currentSelect)}
                  {this.renderSelected(this.state.currentSelect)}
                </div>
              </div>
            </Col>
            <Col xl="6" lg="12" md="12">
              <div style={this.halfBody}>
                <GraficoEquilibrio />
              </div>
            </Col>
          </Row>
        </section>
      </div>
    )
  }
}

export default withContexts(Equilibrio)
