import styled from 'styled-components'

const WrapperForCurrency = styled.div`
  .inputCurrency {
    border-bottom: 1px solid ${({ theme: { colors } }) => colors.secondary} !important;
  }
  input#inputMasked {
    border-bottom: 1px solid ${({ theme: { colors } }) => colors.secondary} !important;
  }
`

export default WrapperForCurrency
