import React, { useCallback } from 'react'
import { Col } from 'reactstrap'
import Select from 'react-select'
import { IFieldSelect } from './types'
import { notNullUndefined } from '../../Utils'

export const FieldSelect = <T extends IFieldSelect<T>>({
  afterContent,
  className,
  defaultValue,
  disabled,
  id,
  label,
  loading,
  onChange,
  options,
  placeholder = 'Selecione...',
  selectedValue,
  size = { lg: 6, xl: 6 }
}: IFieldSelect<T>) => {
  const getValue = useCallback(() => {
    return options.find((item) => {
      const itemId = item?.value?.['id']
      const selectedValueId = selectedValue?.['id']

      if (notNullUndefined(itemId) && notNullUndefined(selectedValueId)) {
        if (typeof itemId === 'string' && typeof selectedValueId === 'string') {
          return itemId === selectedValueId
        }
        return Number(itemId) === Number(selectedValueId)
      } else if (notNullUndefined(selectedValue)) {
        return String(item?.value) === String(selectedValue)
      }
      return false
    })
  }, [options, selectedValue])

  return (
    <Col lg={size.lg} xl={size.xl}>
      {label && <label>{label}</label>}

      <Select
        borderless
        className={className}
        defaultValue={defaultValue}
        disabled={disabled}
        grayscale
        id={id}
        key={getValue()?.label}
        loading={loading}
        onChange={onChange}
        options={options || []}
        placeholder={placeholder}
        selected={getValue() || ''}
        value={getValue()}
      />

      {!!afterContent && afterContent}
    </Col>
  )
}
