import { NavLink } from 'react-router-dom'
import { Navbar } from 'reactstrap'
import Collapse from 'reactstrap/lib/Collapse'
import styled from 'styled-components'
import { NavigationLinks as NavigationsLinksBase } from './components/NavigationLinks'
import { ClientSelector } from './ClientSelector'
import Fitas from '../../theme/fitas'

export const Nav = styled(Navbar)`
  display: flex;
  flex-direction: column;
  padding: 0 !important;
`

export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  width: 100%;

  @media screen and (min-width: 768px) {
    display: block;
    /* padding: 0 1.5rem 1.5rem; */
    /* padding: 0 1.5rem 1.5rem; */
    height: 100%;
  }
`

export const Logo = styled.div<{ $width: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  height: 100%;
  flex: 1;

  img {
    max-width: 170px;
    width: ${({ $width }) => $width};
  }

  @media screen and (min-width: 768px) {
    height: auto;

    img {
      max-width: auto;
    }
  }
`

export const SidebarMenuLink = styled(NavLink)<{ $tipoUsuario: number }>`
  &.active {
    background: ${({ $tipoUsuario, theme: { colors } }) =>
      $tipoUsuario <= 1 ? colors.secondaryDarker : colors.primaryDarker} !important;
    color: #fff !important;
  }
  &.active > .fa-angle-right {
    color: #fff !important;
    background: ${({ $tipoUsuario, theme: { colors } }) =>
      $tipoUsuario <= 1 ? colors.secondaryDarker : colors.primaryDarker} !important;
  }

  &.active,
  &.active i {
    color: #fff !important;
    background: ${({ $tipoUsuario, theme: { colors } }) =>
      $tipoUsuario <= 1
        ? colors.secondaryDarker
        : colors.backgroundSidebarManagerHighlightDarker || colors.primaryDarker} !important;
    border: none !important;
    border-image: none !important;
  }

  &[data-inactive] {
    opacity: 0.5;
  }
`

export const Version = styled.span`
  background-color: ${({ theme }) => theme.colors.secondary};
  border-top: 1px solid ${({ theme }) => theme.colors.secondaryDarker};
  bottom: 0;
  color: white;
  display: none;
  font-size: 0.7rem;
  padding: 1rem 0;
  position: fixed;
  text-align: center;
  width: 240px;
  z-index: 10;

  @media screen and (min-width: 768px) {
    display: block;
  }
`

export const FamilyBackground = styled.div<{ background: string }>`
  background: ${({ background }) => background};
  cursor: pointer;
  display: flex;
  flex-shrink: 0;
  height: 70px;
  justify-content: center;
  margin-top: 10px;
  padding-left: 45;
  position: relative;
  width: 90%;

  @media screen and (min-width: 768px) {
    width: 80%;
    margin: auto;
    margin-left: 10px;
    margin-top: 0.7rem;
    margin-bottom: 0.7rem;
    width: 80%;
    margin: auto;
    margin-left: 10px;
    margin-top: 0.7rem;
    margin-bottom: 0.7rem;
  }
`

export const CollapseContainer = styled(Collapse)`
  padding-bottom: 65px;
`

export const NavigationsLinks = styled(NavigationsLinksBase)`
  padding: 0 1.5rem 1.5rem;
  margin: auto !important;

  @media screen and (min-width: 768px) {
    padding-bottom: 5rem;
  }
`

export const ClientSelect = styled(ClientSelector)`
  margin: 0 auto 1.5rem;
  width: 95%;
`

export const UserTypeBanner = styled(Fitas)`
  margin: 8px 40px;
  width: 100%;

  @media screen and (min-width: 768px) {
    margin: 0 auto 1rem;
    width: 175px;
  }
`
