import React, { forwardRef, useCallback, useEffect, useRef } from 'react'
import * as S from './styles'
import SkyLight from 'react-skylight'

interface IModalV2 {
  open: boolean
  close?: () => void
  beforeOpen?: () => void
  title: string | React.ReactNode
  children: React.ReactNode
  className?: string
  usePrimary?: boolean
  closeable?: boolean
  footer?: React.ReactNode
}

export const ModalV2 = forwardRef(
  (
    {
      open = false,
      close,
      title,
      children,
      beforeOpen,
      className,
      usePrimary,
      closeable = true,
      footer = null
    }: IModalV2,
    ref
  ) => {
    const modalRef = useRef<SkyLight>(ref)

    const enableScroll = useCallback((enabled = true) => {
      const root = document.querySelector('html')
      if (root) {
        root.style.overflow = enabled ? 'unset' : 'hidden'
      }
    }, [])

    const handleKeyDown = useCallback(() => {
      const keysListener = (event: KeyboardEvent) => {
        if (event.key === 'Escape' && closeable) {
          enableScroll(true)
          close?.()
          modalRef?.current?.hide()
        }
      }

      if (!closeable) return document.removeEventListener('keydown', keysListener)
      document.addEventListener('keydown', keysListener)

      return () => {
        document.removeEventListener('keydown', keysListener)
      }
    }, [close, closeable, enableScroll])

    useEffect(() => {
      if (open) handleKeyDown()
    }, [open, handleKeyDown])

    useEffect(() => {
      if (open) {
        if (modalRef && !modalRef?.current?.state?.isVisible) {
          modalRef?.current?.show()
          enableScroll(false)
          return
        }
      }

      if (open && modalRef?.current?.state?.isVisible) {
        modalRef?.current?.hide()
        enableScroll(true)
        return
      }

      if (!open) {
        modalRef?.current?.hide()
        enableScroll(true)
      }
    }, [enableScroll, modalRef, open])

    return (
      <S.Wrapper open={open} className={className} usePrimary={usePrimary} closeable={closeable}>
        <S.StyledSkyLight
          ref={modalRef}
          closeButtonStyles={{
            display: 'none',
            visibility: closeable ? 'vislble' : 'hidden'
          }}
          transitionDuration={150}
          beforeOpen={() => beforeOpen?.()}
          closeOnEsc={closeable}
          beforeClose={() => {
            const root = document.querySelector('html')
            if (root) root.style.overflow = 'unset'
            close?.()
          }}
          dialogStyles={{ borderRadius: '1rem', padding: '2rem' }}
          title={title}
        >
          {children}

          {footer && <S.ModalFooter>{footer}</S.ModalFooter>}
        </S.StyledSkyLight>
      </S.Wrapper>
    )
  }
)
