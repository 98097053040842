import React from 'react'
import { useTheme } from '../../../hooks'
import { useCoreContext } from '../../../hooks/contexts/coreContext/coreProvider'
import { Nav } from 'reactstrap'
import { NavLinkItem } from '../../../theme/wrapperForLinks'
import BetaTag from '../../Utils/BetaTag'
import { useState } from 'react'
import * as S from '../styles'

export const NavigationLinks = ({
  collapseOpen,
  toggleCollapse,
  routes,
  className
}: {
  collapseOpen?: boolean
  toggleCollapse?: () => void
  routes: unknown[]
  className?: string
}) => {
  const { theme } = useTheme()
  const { familiaId, tipoUsuario: _tipoUsuario, userType } = useCoreContext()
  const tipoUsuario = Number(_tipoUsuario)

  const [displayConfigs, setDisplayConfigs] = useState({
    configuracoesCssDisplay: 'none',
    configuracoesIcone: 'ni ni-bold-right'
  })

  const temPermissao = (perfisPermitidos) => {
    if (perfisPermitidos == null) return true

    if (!!familiaId && !perfisPermitidos.includes(2)) {
      return false
    }

    return perfisPermitidos.includes(tipoUsuario)
  }

  const trocarVisibilidadeConfiguracoes = () => {
    let novoValor = 'block'
    let novoIcone = 'ni ni-bold-down'

    if (displayConfigs.configuracoesCssDisplay !== 'none') {
      novoValor = 'none'
      novoIcone = 'ni ni-bold-right'
    }

    setDisplayConfigs({
      configuracoesCssDisplay: novoValor,
      configuracoesIcone: novoIcone
    })
  }

  const renderNavItem = (prop, key) => (
    <li key={key} className="nav-item">
      <NavLinkItem
        key={key}
        $tipoUsuario={tipoUsuario}
        data-inactive={prop.inactive}
        to={prop.layout + prop.path || '#'}
        // tag={NavLinkItemRRD}
        activeClassName="active"
        className="nav-link"
      >
        <i className={prop.icon} style={{ paddingRight: 10 }} />
        {prop.name}
      </NavLinkItem>
    </li>
  )

  const createLinks = (routes) => {
    if (routes == null) {
      return
    }

    return routes
      .map((prop, key) => {
        if (
          prop.hideOnSidebar === true || //Se é para esconder no sidebar
          prop.configurationMenu === true || //Se é menu de configuracao
          prop.userConfigurationMenu === true || //Se é menu de configuracao de usuario
          (prop.perfis != null && !temPermissao(prop.perfis)) //se tipo usuario incompatível com perfil do menu
        ) {
          //Se necessita de planejador principal (caso planejador)
          return null
        }

        if (!familiaId) {
          return null
        } else if (prop.header) {
          return (
            <li style={{ paddingLeft: '1.4rem', fontSize: '1.25rem' }} key={key}>
              <h4 style={{ color: 'white', marginBottom: '0.3rem' }}>
                <i style={{ marginRight: '.2rem', marginTop: 15 }} className={prop.icon} /> {prop.header}
              </h4>
            </li>
          )
        }

        if (prop.redirect) {
          const { url } = prop.redirect()
          return (
            <li className="nav-item">
              <a href={url} title={prop.name} className="nav-link">
                <i className={prop.icon} style={{ fontSize: 10, color: '#FFF' }} />
                {prop.name}
                {prop.betaTag && <BetaTag />}
              </a>
            </li>
          )
        }

        return (
          <li key={key} className="nav-item">
            <S.SidebarMenuLink
              // to="#"
              $tipoUsuario={tipoUsuario}
              key={key}
              className="nav-link"
              to={prop.layout + prop.path}
              // tag={NavLinkRRD}
              style={{ paddingRight: 0 }}
              data-inactive={prop.inactive}
            >
              <i className={prop.icon} style={{ fontSize: 10, color: '#FFF' }} />
              {prop.name}
              {prop.betaTag && <BetaTag />}
            </S.SidebarMenuLink>
          </li>
        )
      })
      .filter((a) => a)
  }

  const createConfigLinks = (routes) => {
    if (routes == null) {
      return <></>
    }

    const rawShowFamilyPlanner = ['/usuarios', '/meios-pagamento', '/wizard']

    return routes
      .map((prop, key) => {
        //console.log("prop: ", prop.path, "key", key);
        const rawTest = familiaId !== null && rawShowFamilyPlanner.includes(prop.path)
        if (
          (rawTest === false && prop.name === 'Clientes' && familiaId !== null && familiaId !== undefined) ||
          prop.hideOnSidebar === true || //Se é para esconder no sidebar
          prop.configurationMenu !== true || //Se não é menu de configuracao
          (prop.perfis != null && !temPermissao(prop.perfis)) || //Se tipo usuario incompatível com perfil do menu
          (prop.onlyManager === true && !userType)
        ) {
          //Se necessita de planejador principal (caso planejador)
          return null
          // return <div key={key}></div>
        }

        if (familiaId !== null) {
          if (prop.verificacaoFamiliaSelecionada === true) {
            return renderNavItem(prop, key)
          }
        } else {
          if (!rawShowFamilyPlanner.includes(prop.path)) return renderNavItem(prop, key)
        }
        return null
      })
      ?.filter((a) => a)
  }

  return (
    <S.CollapseContainer navbar isOpen={collapseOpen} className={`always-visible ${className}`}>
      <button
        className="navbar-toggler navbar-toggler-icon"
        type="button"
        onClick={toggleCollapse}
        style={{
          backgroundColor: theme.colors?.backgroundSidebarManagerDarker || theme.colors.secondaryDarker,
          width: 30,
          height: 30
        }}
      />

      <Nav navbar>{createLinks(routes)}</Nav>

      <hr className="my-3" />

      <h6
        className="navbar-heading text-muted"
        onClick={() => trocarVisibilidadeConfiguracoes()}
        style={{ width: '100%', marginLeft: '-10px' }}
      >
        <label style={{ marginBottom: 0, color: '#FFF' }}>Configurações</label>
        <i
          className={displayConfigs.configuracoesIcone}
          style={{
            fontSize: '15px',
            float: 'left',
            marginTop: '1px',
            marginRight: '5px',
            color: '#FFF'
          }}
        />
      </h6>

      <Nav navbar style={{ display: displayConfigs.configuracoesCssDisplay }}>
        {createConfigLinks(routes)}
      </Nav>
    </S.CollapseContainer>
  )
}
