import { format } from 'date-fns'

export const setEndOfDay = (date: Date) => {
  const adjustedDate = new Date(date)
  adjustedDate.setHours(23, 59, 59, 0)

  return adjustedDate
}

export const setStartOfDay = (date: Date) => {
  const adjustedDate = new Date(date)
  adjustedDate.setHours(0, 0, 0, 0)

  return adjustedDate
}

export const formatDateStart = (dateStart: Date) => {
  const formattedDate = format(setStartOfDay(new Date(dateStart)), "yyyy-MM-dd'T'HH:mm:ss")
  return formattedDate
}

export const formatDateEnd = (dateEnd: Date) => {
  const formattedDate = format(setEndOfDay(dateEnd), "yyyy-MM-dd'T'HH:mm:ss")
  return formattedDate
}

export const monthRange = (date: Date | string) => {
  let baseDate = date as Date
  if (typeof date === 'string') {
    baseDate = new Date(date.split('T')[0])
  } else {
    baseDate = new Date(date)
  }

  const start = formatDateStart(baseDate)
  const end = formatDateEnd(baseDate)

  return { start, end }
}
