import { useQuery } from 'react-query'
import {
  Params as GetInvestmentsByUserIdParams,
  Response as GetInvestmentsByUserIdResponse,
  getInvestmentsByUserId
} from '../../domain/investments/api/getInvestmentsByUserId'
import {
  Params as GetInvestmentsByItemIdParams,
  Response as GetInvestmentsByItemIdResponse,
  getInvestmentsByItemId
} from '../../domain/investments/api/getInvestmentsByItemId'
import { QueryOptions } from 'react-query'
import {
  getManualInvestments,
  getInvestmentsTypes,
  getFinancialInstitutionApi,
  getAllEquitysApi,
  getAllTransactions
} from '../../domain/investments/api/ManualInvestments/manualInvestments'
import {
  IAllInvestment,
  IGetAllInvestmentsRequest,
  IGetAllTransactions
} from '../../domain/investments/api/ManualInvestments/types'
import { useCoreContext } from '../../hooks/contexts/coreContext/coreProvider'
import { cacheBaseKey as lancamentoCacheKey } from '../lancamento/queries'

export const cacheBaseKey = ['investments', lancamentoCacheKey]

type UseInvestmentsByUserIdParams = {
  options?: QueryOptions<GetInvestmentsByUserIdResponse, Error>
} & GetInvestmentsByUserIdParams

export const useInvestmentsByUserIdQuery = ({
  userId,
  desc = true,
  options,
  orderBy,
  pageIndex,
  pageSize,
  columnLevelOne,
  columnLevelTwo
}: UseInvestmentsByUserIdParams) => {
  const {
    data: investmentsByUserId,
    isError: isInvestmentsByUserIdError,
    isLoading: isLoadingInvestmentsByUserId,
    error: investmentsByUserIdError,
    refetch: refetchGetInvestmentsByUserId,
    isRefetching: isRefetchingInvestmentsByUserId
  } = useQuery(
    [
      ...cacheBaseKey,
      userId,
      'getInvestmentsByUserId',
      desc,
      orderBy,
      pageIndex,
      pageSize,
      columnLevelOne,
      columnLevelTwo
    ],
    () =>
      getInvestmentsByUserId({ userId, desc, orderBy, pageIndex, pageSize, columnLevelOne, columnLevelTwo }),
    options
  )

  return {
    investmentsByUserId,
    investmentsByUserIdError,
    isLoadingInvestmentsByUserId,
    isInvestmentsByUserIdError,
    isRefetchingInvestmentsByUserId,
    refetchGetInvestmentsByUserId
  }
}

type UseInvestmentsByItemIdParams = {
  options?: QueryOptions<GetInvestmentsByItemIdResponse, Error>
} & GetInvestmentsByItemIdParams

export const useInvestmentsByItemIdQuery = ({
  itemId,
  desc = true,
  options,
  pageIndex,
  pageSize
}: UseInvestmentsByItemIdParams) => {
  const {
    data: investmentsByItemId,
    isError: isInvestmentsByItemIdError,
    isLoading: isLoadingInvestmentsByItemId,
    error: investmentsByItemIdError,
    refetch: refetchGetInvestmentsByItemId
  } = useQuery(
    [...cacheBaseKey, itemId, 'getInvestmentsByItemId', desc, pageIndex, pageSize],
    () => getInvestmentsByItemId({ itemId, desc, pageIndex, pageSize }),
    options
  )

  return {
    investmentsByItemId,
    isInvestmentsByItemIdError,
    isLoadingInvestmentsByItemId,
    investmentsByItemIdError,
    refetchGetInvestmentsByItemId
  }
}

export const useGetInvestmentsTypes = () => {
  const {
    data: investmentsTypes,
    isError: isInvestmentstypesError,
    isLoading: isLoadingInvestmentstypes,
    error: investmentsTypesError
  } = useQuery(cacheBaseKey, () => getInvestmentsTypes())

  return {
    investmentsTypes,
    isInvestmentstypesError,
    isLoadingInvestmentstypes,
    investmentsTypesError
  }
}

export const useGetFinancialInstitution = () => {
  const { authenticated, familiaId } = useCoreContext()

  const {
    data: financialInstitutions,
    refetch: refetchGetFinancialInstituions,
    isError: isErrorFinancialInstituions,
    isLoading: isLoadingFinancialInstituions,
    error: financialInstitutionsError
  } = useQuery(cacheBaseKey, () => getFinancialInstitutionApi(), {
    enabled: authenticated && !!familiaId
  })

  return {
    financialInstitutions,
    isErrorFinancialInstituions,
    isLoadingFinancialInstituions,
    financialInstitutionsError,
    refetchGetFinancialInstituions
  }
}

export const useGetAllEquitys = ({ familyId, params }: IGetAllInvestmentsRequest) => {
  const { authenticated, familiaId } = useCoreContext()

  const {
    data: allEquitys,
    isError: isErrorGetAllEquitys,
    isLoading: isLoadingGetAllEquitys,
    error: getAllEquitysError,
    refetch: refetchGetAllEquitys,
    isFetched: allEquitysIsFetched
  } = useQuery(
    [...cacheBaseKey, familyId, params],
    () => getAllEquitysApi({ familyId: familyId ?? familiaId, params }),
    {
      enabled: authenticated && (!!familyId || !!familiaId)
    }
  )

  const { investments, fixedAsset } = allEquitys || {}
  const allInvestments: IAllInvestment[] = []

  if (investments?.length) {
    investments?.forEach((investment) => {
      investment?.groupedInvestments?.forEach((grouped) =>
        grouped?.investments?.forEach((investmentItem) => allInvestments.push(investmentItem))
      )
    })
  }

  const manualPatrimonies = allInvestments?.filter((item) => item.isManual)

  return {
    allEquitys,
    allEquitysIsFetched,
    allInvestments,
    fixedAsset: fixedAsset || [],
    getAllEquitysError,
    investments,
    isErrorGetAllEquitys,
    isLoadingGetAllEquitys,
    manualPatrimonies,
    refetchGetAllEquitys
  }
}

export const useGetAllTransactions = ({ investmentId, params }: IGetAllTransactions) => {
  const {
    data: allTransactions,
    isError: isErrorGetAllTransactions,
    isLoading: isLoadingGetAllTransactions,
    error: getAllTransactionsError,
    refetch: refetchGetAllTransactions
  } = useQuery([...cacheBaseKey, investmentId, params], () => getAllTransactions({ investmentId, params }), {
    retry: false
  })

  // const totalPages = allTransactions ? Math.ceil(allTransactions?.totalResults / allTransactions?.pageSize) : 0

  return {
    allTransactions,
    isErrorGetAllTransactions,
    isLoadingGetAllTransactions,
    getAllTransactionsError: getAllTransactionsError as Error,
    // totalPages,
    refetchGetAllTransactions
  }
}

export const useGetManualInvestments = ({ userId }: { userId: number }) => {
  const { authenticated } = useCoreContext()
  const {
    data: manualInvestments,
    isError: isManualInvestmentsError,
    isLoading: isLoadingManualInvestments,
    error: manualInvestmentsError
  } = useQuery(
    [...cacheBaseKey, userId],
    () => (userId && authenticated ? getManualInvestments({ userId }) : null),
    {
      retry: false
    }
  )

  return {
    manualInvestments,
    isManualInvestmentsError,
    isLoadingManualInvestments,
    manualInvestmentsError
  }
}
