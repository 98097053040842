import React, { useCallback, useState } from 'react'
import * as S from './styles'
import { BaseFieldValues, ITextareaControl } from './types'
import { Controller } from 'react-hook-form'

const MAX_LENGTH = 1024

export const TextareaControl = <T extends BaseFieldValues>({
  name,
  rules,
  placeholder,
  disabled,
  control,
  defaultValue,
  maxLength = MAX_LENGTH,
  errors
}: ITextareaControl<T>) => {
  const [_value, setValue] = useState(defaultValue)
  const errorMessage = errors?.[name]?.message
  const _valueLength = _value?.length || 0
  const isMaxLength = _valueLength > maxLength

  const ErrorsMessages = useCallback(
    () => (
      <S.Counter>
        <div>{errorMessage && <S.Error>{errorMessage.toString()}</S.Error>}</div>
        <S.LengthContainer>
          {isMaxLength ? <S.Error>{_valueLength}</S.Error> : _valueLength} <span>&nbsp;/&nbsp;</span>{' '}
          {maxLength}
        </S.LengthContainer>
      </S.Counter>
    ),
    [_valueLength, errorMessage, isMaxLength, maxLength]
  )

  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const { value } = event.target
    setValue(value)
  }

  return (
    <Controller
      control={control}
      name={name}
      rules={{
        ...rules,
        maxLength: {
          value: maxLength,
          message: `O campo deve ter no máximo ${maxLength} caracteres`
        }
      }}
      render={({ field }) => (
        <S.Container>
          {/* @ts-ignore */}
          <S.TextArea
            {...field}
            disabled={disabled}
            placeholder={placeholder}
            value={_value}
            onChange={(event) => {
              field.onChange(event)
              handleChange(event)
            }}
          />
          <ErrorsMessages />
        </S.Container>
      )}
    />
  )
}
