import React from 'react'
import * as S from '../styles'

interface TitleProps {
  title?: string
  subTitle?: string | null | undefined
}

export const Title: React.FC<TitleProps> = ({ title = 'Diagnóstico Financeiro', subTitle }) => {
  return (
    <S.Header>
      <S.Title>{title}</S.Title>
      {subTitle && <S.SubTitle>{subTitle}</S.SubTitle>}
    </S.Header>
  )
}
