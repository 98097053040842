import { useQuery, useMutation } from 'react-query'
import {
  getDreamImageByDreamIdApi,
  getPlansDreamsByFamilyIdApi,
  postCreateDreamApi
} from '../../domain/plansDreams/api'
import { useState } from 'react'

export const useGetPlansDreamsByFamilyId = () => {
  const {
    data: plansDreams,
    isLoading: isLoadingPlansDreams,
    isError: isErrorPlansDreams,
    error: plansDreamsError,
    mutateAsync: getPlansDreamsByFamilyId
  } = useMutation(getPlansDreamsByFamilyIdApi)

  return { getPlansDreamsByFamilyId, plansDreams, isLoadingPlansDreams, isErrorPlansDreams, plansDreamsError }
}

export const useGetDreamImageByDreamId = ({ dreamId }) => {
  const {
    data: dreamImage,
    isLoading: isLoadingDreamImage,
    isError: isErrorDreamImage,
    error: dreamImageError
  } = useQuery<unknown, Error, string>(
    ['dreamImage', dreamId],
    () => getDreamImageByDreamIdApi({ dreamId }),
    { enabled: !!dreamId }
  )

  return { dreamImage, isLoadingDreamImage, isErrorDreamImage, dreamImageError }
}

export const useGetDreamImageListByDreamId = ({ dreamIdList }) => {
  const {
    data: dreamImageList,
    isLoading: isLoadingDreamImageList,
    isError: isErrorDreamImageList,
    error: dreamImageListError
  } = useQuery<unknown, Error, string[]>(
    ['dreamImageList', dreamIdList],
    () => Promise.all(dreamIdList.map((dreamId) => getDreamImageByDreamIdApi({ dreamId }))),
    { enabled: !!dreamIdList }
  )

  return { dreamImageList, isLoadingDreamImageList, isErrorDreamImageList, dreamImageListError }
}

export const useMutationDreamImageListByDreamId = () => {
  const [dreamImageList, setDreamImageList] = useState<string[]>([])
  const {
    mutateAsync: getDreamImageByDreamId,
    isLoading: isLoadingGetDreamImageByDreamId,
    isError: isErrorGetDreamImageByDreamId,
    error: errorGetDreamImageByDreamId,
    isSuccess: isSuccessGetDreamImageByDreamId,
    reset: resetGetDreamImageByDreamId
  } = useMutation(getDreamImageByDreamIdApi)

  const getDreamImageByDreamIdAsync = async (dreamIdList?: number[]) =>
    dreamIdList?.length &&
    Promise.all(dreamIdList?.map((dreamId) => getDreamImageByDreamId({ dreamId })))
      .then((result) => {
        const dreamImageList: string[] = []

        result?.forEach((item) => {
          dreamImageList?.push(item)
        })

        setDreamImageList(dreamImageList)
        return dreamImageList
      })
      .catch((error) => {
        console.error('error', { error })
        setDreamImageList([])
        return error
      })

  return {
    getDreamImageByDreamIdAsync,
    isLoadingGetDreamImageByDreamId,
    isErrorGetDreamImageByDreamId,
    errorGetDreamImageByDreamId,
    isSuccessGetDreamImageByDreamId,
    resetGetDreamImageByDreamId,
    dreamImageList
  }
}

export const usePostCreateDreamQuery = () => {
  const {
    mutateAsync: postCreateDream,
    isLoading: isLoadingPostCreateDream,
    isError: isErrorPostCreateDream,
    error: errorPostCreateDream
  } = useMutation('post-dream', postCreateDreamApi, {
    retry: 2,
    retryDelay: 3000
  })

  return {
    postCreateDream,
    isLoadingPostCreateDream,
    isErrorPostCreateDream,
    errorPostCreateDream
  }
}
