import React from 'react'
import MUIDataTable from 'mui-datatables'
import CurrencyInput from 'react-currency-input'
import axios from 'axios'
import { registerLocale } from 'react-datepicker'
import br from 'date-fns/locale/pt-BR'
import { createTheme, MuiThemeProvider } from '@material-ui/core/styles'
import WrapperForCurrency from '../../../../theme/wrapperForCurrency'
import { withContexts } from '../../../../components'

const theme = createTheme({
  overrides: {
    MuiTableRow: {
      root: {
        '&:nth-of-type(odd)': {
          backgroundColor: '#f8f9fe'
        }
      }
    },
    MuiTableCell: {
      root: {
        fontFamily: 'unset',
        borderTop: 'none',
        padding: '16px 10px'
      }
    },
    MUIDataTableHeadCell: {
      root: {
        fontWeight: 700
      }
    },
    MuiPaper: {
      root: {
        overflow: 'hidden',
        margin: 0
      },
      elevation4: {
        boxShadow: 'none',
        border: '0.5px solid rgb(228, 234, 244)'
      }
    },
    MUIDataTableToolbar: {
      left: {
        margin: 10
      }
    },
    MuiToolbar: {
      regular: {
        marginTop: 16
      }
    }
  }
})

registerLocale('br', br)

class InvestirMais extends React.Component {
  moment = require('moment')

  componentDidMount() {
    var $this = this

    this.setState(
      {
        familiaId: this.props.familiaId
      },
      $this.refreshData()
    )
  }

  getData = () => {
    const url = global.server_api_new + '/EquilibrioFinanceiro/familia/' + this.state.familiaId + '/plano'

    const filtro = {
      DataInicial: this.state.startDate,
      DataFinal: this.state.endDate
    }

    axios.post(url, filtro).then((res) => {
      const data = []
      let totalMeta = 0
      let totalAtual = 0
      let totalParcela = 0

      if (res.data.results != null) {
        res.data.results.forEach((item) => {
          data.push({
            id: item.id,
            planoId: item.planoId,
            nome: item.nome,
            ideal: item.ideal,
            mesAtual: item.mesAtual,
            ehTotal: 0
          })
          totalAtual += item.mesAtual != null ? item.mesAtual : 0
          totalMeta += item.ideal != null ? item.ideal : 0
        })
      }

      var urlFamilia = `${global.server_api_new}/sonho/planos/familia/${this.state.familiaId}/emAndamento`
      axios.get(urlFamilia).then((res) => {
        res.data.results.forEach((first) => {
          data.forEach((second) => {
            if (first.planoId === second.planoId) {
              second.parcela = first.parcela
            }
          })
          totalParcela += first.parcela
        })
        //console.log('Hello world!', data);
        data.push({
          nome: 'Total',
          ideal: totalMeta,
          mesAtual: totalAtual,
          parcela: totalParcela,
          ehTotal: 1
        })
        this.setState({ data })
      })
    })
  }

  setStartDate = (date, callback) => {
    if (date != null) {
      date.setHours(0)
      date.setMinutes(0, 0, 0)
      this.props.setDataInicio(this.moment(date).format('YYYY-MM-DDTHH:mm:ss'))
    }

    const startDate = date

    this.setState({ startDate }, function () {
      if (callback != null) {
        callback()
      } else {
        this.getData()
      }
    })
  }

  setEndDate = (date, callback) => {
    if (date != null) {
      date.setHours(23)
      date.setMinutes(59, 59, 59)
      this.props.setDataFim(this.moment(date).format('YYYY-MM-DDTHH:mm:ss'))
    }

    const endDate = date

    this.setState({ endDate }, function () {
      if (callback != null) {
        callback()
      } else {
        this.getData()
      }
    })
  }

  state = {
    data: []
  }

  columns = [
    {
      name: 'ehTotal',
      options: {
        display: false,
        viewColumns: false,
        filter: false
      }
    },
    {
      name: 'id',
      options: {
        display: false,
        viewColumns: false,
        filter: false
      }
    },
    {
      name: 'planoId',
      options: {
        display: false,
        viewColumns: false,
        filter: false
      }
    },
    {
      name: 'nome',
      label: 'Nome',
      options: {
        display: true,
        viewColumns: true,
        filter: false
      }
    },
    {
      name: 'parcela',
      label: 'Estimado',
      options: {
        display: true,
        viewColumns: true,
        filter: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          if (value == null) value = 0

          return (
            <div>
              {value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }).replace('R$', '')}
            </div>
          )
        }
      }
    },
    {
      name: 'ideal',
      label: 'Ideal',
      options: {
        display: true,
        viewColumns: true,
        filter: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          if (value == null) value = 0

          if (tableMeta.rowData[0] === 1) {
            return (
              <div>
                {value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }).replace('R$', '')}
              </div>
            )
          }

          return (
            <div>
              <WrapperForCurrency>
                <CurrencyInput
                  style={{ width: '100px' }}
                  className="inputCurrency"
                  value={value}
                  thousandSeparator={'.'}
                  precision="2"
                  onChangeEvent={(event, formattedValue, value) => {
                    updateValue(value)

                    if (this.state.timeout != null) {
                      clearTimeout(this.state.timeout)
                    }

                    var $this = this

                    var timeout = setTimeout(function () {
                      var planoId = tableMeta.rowData[2]
                      //var service = 'api/EquilibrioFinanceiro/familia/' +$this.state.familiaId+ '/plano/'+planoId;
                      var service = `/EquilibrioFinanceiro/familia/${$this.state.familiaId}/plano/${planoId}`
                      var url = global.server_api_new + service

                      var dados = {
                        ideal: value
                      }

                      axios.post(url, dados).then((res) => {
                        if (res.data.success) {
                          $this.getData()
                        }
                      })
                    }, 1000)

                    this.setState({ timeout })
                  }}
                />
              </WrapperForCurrency>
            </div>
          )
        }
      }
    },
    {
      name: 'mesAtual',
      label: 'Realizado',
      options: {
        display: true,
        viewColumns: true,
        filter: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          if (value == null) value = 0

          return (
            <div>
              {value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }).replace('R$', '')}
            </div>
          )
        }
      }
    },
    {
      name: 'diferenca',
      label: 'Diferença',
      options: {
        display: true,
        viewColumns: true,
        filter: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          var atual = tableMeta.rowData[4]
          var meta = tableMeta.rowData[5]

          if (atual == null) {
            atual = 0
          }

          if (meta == null) {
            meta = 0
          }

          value = atual - meta

          return (
            <div>
              {value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }).replace('R$', '')}
            </div>
          )
        }
      }
    }
  ]

  options = {
    filter: true,
    filterType: 'dropdown',
    responsive: 'stacked',
    print: false,
    download: false,
    sort: true,
    pagination: false,
    selectableRows: 'none',
    textLabels: global.textLabels
  }

  refreshData = () => {
    var $this = this

    var startDate = this.props.dataInicio
    var endDate = this.props.dataFim

    this.setStartDate(startDate, function () {
      $this.setEndDate(endDate, function () {
        // console.log('RefreshData 2');
        $this.getData()
      })
    })
  }

  render() {
    return (
      <>
        <button
          id="investirMais"
          onClick={() => this.refreshData()}
          style={{ position: 'fixed', left: '-1000px' }}
        />
        <MuiThemeProvider theme={theme}>
          <MUIDataTable
            //title={'Investir mais'}
            data={this.state.data}
            columns={this.columns}
            options={this.options}
          />
        </MuiThemeProvider>
      </>
    )
  }
}
export default withContexts(InvestirMais)
