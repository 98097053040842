import React, { useMemo } from 'react'
import * as S from './styles'
import { Title } from '../Title'
import { DreamCardList } from './DreamCard'
import { IFormCreateDiagnostic } from './types'
import { formatCurrencyBRL } from '../../../Utils'
import { TextareaControl } from './TextareaControll'
import { CheckboxControlList } from './CheckboxControl'
import { useCreateDiagnosticMethods } from './useCreateDiagnosticMethods'

export const CreateDiagnostic = () => {
  const {
    control,
    desafios,
    Errors: errors,
    fixedAssetTotal,
    formatDate,
    handleCheckboxControl,
    handleSubmit,
    investmentsReal,
    isDisabled,
    isLoading,
    modalCreateDiagnostic,
    onSubmit,
    orcamentoDebtsRealTotal,
    orcamentoDespesasReceitas,
    pontosFortes,
    prioridades,
    submitButtonRef,
    topExpenses,
    topExpensesTotals,
    startDate,
    endDate,
    showCreateDiagnostic,
    plansDreamsListWithImage
  } = useCreateDiagnosticMethods()

  const PageTitle = () =>
    useMemo(() => <Title subTitle={`${formatDate(startDate)} até ${formatDate(endDate)}`} />, [])

  return (
    <S.ModalV2
      title={<PageTitle />}
      className="modal-diagnostico"
      open={showCreateDiagnostic}
      closeable={!isLoading}
      close={() => modalCreateDiagnostic(false)}
      footer={
        <S.ButtonCreate
          onClick={() => submitButtonRef?.current?.click()}
          text="Salvar e Exportar"
          loading={isLoading}
          disabled={isDisabled}
          invertedColors
        />
      }
    >
      <S.Content>
        <form onSubmit={handleSubmit(onSubmit)}>
          <S.Fieldset disabled={isLoading}>
            <S.FormSection>
              <S.FormTitle>Planos em progresso:</S.FormTitle>
              <DreamCardList plansDreamsListWithImage={plansDreamsListWithImage} />
            </S.FormSection>

            <S.FormSection>
              <S.FormTitle>Orçamento:</S.FormTitle>
              {/* Tabela Receita x Despesa */}
              <S.Table>
                <thead>
                  <tr>
                    <th></th>
                    <th>Estimado</th>
                    <th>Realizado*</th>
                  </tr>
                </thead>
                <tbody>
                  {orcamentoDespesasReceitas?.map((item) => (
                    <tr key={item.id}>
                      <td>{item.nome}</td>
                      <td>{formatCurrencyBRL(item.estimado || 0)}</td>
                      <td>{formatCurrencyBRL(item.totalMedio || 0)}</td>
                    </tr>
                  ))}
                </tbody>
                <tfoot>
                  <tr>
                    <td colSpan={3} style={{ textAlign: 'left', fontSize: '0.8rem', color: 'gray' }}>
                      *Quando o período for maior que 30 dias, o valor realizado exibido corresponde à média
                      mensal.
                    </td>
                  </tr>
                </tfoot>
              </S.Table>

              {topExpenses?.Categories?.length > 0 && (
                <S.FormTitle>TOP {topExpenses?.Categories?.length} maiores despesas:</S.FormTitle>
              )}

              {topExpenses?.Categories?.length > 0 && (
                /* Tabela com Top 6 Despesas*/
                <S.Table>
                  <thead>
                    <tr>
                      <th></th>
                      <th>Estimado</th>
                      <th>Realizado*</th>
                    </tr>
                  </thead>
                  <tbody>
                    {topExpenses?.Categories?.map((category, index) => (
                      <tr key={`${category.Name}-${index}`}>
                        <td>{category.Name}</td>
                        <td>{formatCurrencyBRL(category.IdealValue)}</td>
                        <td>{formatCurrencyBRL(category.ActualValue)}</td>
                      </tr>
                    ))}
                  </tbody>
                  <tfoot>
                    <tr>
                      <td></td>
                      <td>{formatCurrencyBRL(topExpensesTotals?.idealValue)}</td>
                      <td>{formatCurrencyBRL(topExpensesTotals?.actualValue)}</td>
                    </tr>
                    <tr>
                      <td colSpan={4} style={{ textAlign: 'left', fontSize: '0.8rem', color: 'gray' }}>
                        *Quando o período for maior que 30 dias, o valor realizado exibido corresponde à média
                        mensal.
                      </td>
                    </tr>
                  </tfoot>
                </S.Table>
              )}
              <S.FormSubtitle>Análise orçamentária:</S.FormSubtitle>
              <TextareaControl<IFormCreateDiagnostic>
                name="BudgetObservation"
                placeholder="Descreva a análise"
                disabled={isLoading}
                control={control}
                errors={errors}
              />
            </S.FormSection>

            <S.FormSection>
              <S.FormTitle>Patrimônio:</S.FormTitle>
              {/* Tabela Financeiro x Imobilizado x Dívidas x Patrimonio Líquido */}
              <S.Table>
                <thead>
                  <tr>
                    <th></th>
                    <th>Valor total</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Financeiro</td>
                    <td>{formatCurrencyBRL(investmentsReal)}</td>
                  </tr>
                  <tr>
                    <td>Imobilizado</td>
                    <td>{formatCurrencyBRL(fixedAssetTotal)}</td>
                  </tr>
                  <tr>
                    <td>Dívidas</td>
                    <td>{formatCurrencyBRL(orcamentoDebtsRealTotal)}</td>
                  </tr>
                </tbody>
                <tfoot>
                  <tr>
                    <td>Patrimonio Líquido</td>
                    <td>{formatCurrencyBRL(investmentsReal + fixedAssetTotal - orcamentoDebtsRealTotal)}</td>
                  </tr>
                </tfoot>
              </S.Table>
              <S.FormSubtitle>Análise patrimonial:</S.FormSubtitle>
              <TextareaControl<IFormCreateDiagnostic>
                name="PatrimonyObservation"
                placeholder="Descreva a análise"
                disabled={isLoading}
                control={control}
                errors={errors}
              />
            </S.FormSection>

            <S.FormSection>
              <S.FormTitle>Conclusão Geral</S.FormTitle>
              <S.FormSubtitle>{pontosFortes?.groupName}:</S.FormSubtitle>
              <S.FormGroup padding>
                <CheckboxControlList<IFormCreateDiagnostic>
                  options={pontosFortes?.options?.map(({ id, name }) => ({
                    label: name,
                    value: id
                  }))}
                  onChange={({ name, checked }) => handleCheckboxControl({ id: name, checked })}
                  control={control}
                />
              </S.FormGroup>
              <S.FormSubtitle>Análise dos {pontosFortes?.groupName.toLowerCase()}:</S.FormSubtitle>
              <TextareaControl<IFormCreateDiagnostic>
                name="StrongPointComment"
                placeholder="Descreva a análise"
                disabled={isLoading}
                control={control}
                errors={errors}
              />
            </S.FormSection>

            <S.FormSection>
              <S.FormSubtitle>{desafios?.groupName}:</S.FormSubtitle>

              <S.FormGroup padding>
                <CheckboxControlList<IFormCreateDiagnostic>
                  options={desafios?.options?.map(({ id, name }) => ({
                    label: name,
                    value: id
                  }))}
                  onChange={({ name, checked }) => handleCheckboxControl({ id: name, checked })}
                  control={control}
                />
              </S.FormGroup>

              <S.FormSubtitle>Análise dos {desafios?.groupName.toLowerCase()}:</S.FormSubtitle>
              <TextareaControl<IFormCreateDiagnostic>
                name="ChallengeComment"
                placeholder="Descreva a análise"
                disabled={isLoading}
                control={control}
                errors={errors}
              />
            </S.FormSection>

            <S.FormSection>
              <S.FormSubtitle>{prioridades?.groupName?.trim()}:</S.FormSubtitle>

              <S.FormGroup padding>
                <CheckboxControlList<IFormCreateDiagnostic>
                  options={prioridades?.options?.map(({ id, name }) => ({
                    label: name,
                    value: id
                  }))}
                  onChange={({ name, checked }) => handleCheckboxControl({ id: name, checked })}
                  control={control}
                />
              </S.FormGroup>

              <S.FormSubtitle>Análise das prioridades:</S.FormSubtitle>
              <TextareaControl<IFormCreateDiagnostic>
                name="PriorityComment"
                placeholder="Descreva a análise"
                disabled={isLoading}
                control={control}
                errors={errors}
              />
            </S.FormSection>

            <S.HiddenButton ref={submitButtonRef} type="submit" disabled={isDisabled}>
              Criar
            </S.HiddenButton>
          </S.Fieldset>
        </form>
      </S.Content>

      <S.SubmitingOverlay isVisible={Boolean(isLoading)} />
    </S.ModalV2>
  )
}
