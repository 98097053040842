import { useFamilyStore } from '../../../store/family/useFamilyStore'
import * as S from './styles'
import { useCoreContext } from '../../../hooks/contexts/coreContext/coreProvider'
import { useTheme } from '../../../hooks'
import { useGetImageBYFamilyId } from '../../../queries/family/queries'
import { useEffect } from 'react'

export const ClientSelector = ({ onClick, className }: { onClick: () => void; className?: string }) => {
  const { tipoUsuario: userType, familiaId } = useCoreContext()
  const { family, defaultImage, setFamilyImage } = useFamilyStore()
  const { familyImage, isLoadingFamilyImage } = useGetImageBYFamilyId({
    familyId: familiaId ? Number(familiaId) : undefined
  })
  const role = Number(userType)

  const {
    theme: { colors }
  } = useTheme()

  const colorScheme =
    role === 2
      ? { dark: colors?.primaryDarker, light: colors?.primary }
      : { dark: colors?.secondaryDarker, light: colors?.secondary }

  useEffect(() => {
    if (familyImage) {
      let imageUrl = familyImage
      if (familyImage.includes('base64') || familyImage.includes('http')) {
        imageUrl = familyImage
      } else {
        imageUrl = `https://app.meuvista.com/v1${familyImage}`
      }
      setFamilyImage(imageUrl)
    }
  }, [familyImage, setFamilyImage])

  return (
    <S.FamilyContainer className={className} colorSqueme={colorScheme} onClick={onClick}>
      <S.FamilyFigure>
        <S.Loader isVisible={isLoadingFamilyImage}>
          <S.LoaderIcon />
        </S.Loader>
        <img src={family?.image || defaultImage} alt={family?.nome || ''} />
      </S.FamilyFigure>

      <div>
        <S.FamilyName>{family?.nome || 'Selecione um cliente'}</S.FamilyName>
      </div>

      <S.FamilyIconContainer>
        <S.FamilyIcon className="fas fa-chevron-right" />
      </S.FamilyIconContainer>
    </S.FamilyContainer>
  )
}
