import React, { useMemo, useState } from 'react'
import * as S from './styles'
import { IPlansDreams } from '../../../../../domain/plansDreams/api'
import { formatCurrencyBRL } from '../../../../Utils'

export const DreamCard = ({ dream }: { dream: IPlansDreams }) => {
  const [isLoadingDreamImage, setIsLoadingDreamImage] = useState(true)
  const { dreamImage } = dream || {}

  return useMemo(
    () => (
      <S.Figure>
        <S.ImageContainer>
          <S.Loader isVisible={isLoadingDreamImage}>
            <S.LoaderIcon />
          </S.Loader>
          <S.Image onLoad={() => setIsLoadingDreamImage(false)} src={dreamImage} alt={dream.descricao} />
        </S.ImageContainer>
        <S.FigureCaption>{dream.descricao}</S.FigureCaption>
        <S.AdditionalInfo>
          <S.AdditionalInfoDetail>Em até {dream.prazo} meses</S.AdditionalInfoDetail>
          <S.AdditionalInfoDetail>
            Valor Futuro: {formatCurrencyBRL(dream.valorEstimadoFuturo)}
          </S.AdditionalInfoDetail>
          <S.AdditionalInfoDetail>
            Acumulado: {formatCurrencyBRL(dream.valorEstimadoPresente)}
          </S.AdditionalInfoDetail>
          <S.AdditionalInfoDetail>Parcela: {formatCurrencyBRL(dream.parcela)}</S.AdditionalInfoDetail>
        </S.AdditionalInfo>
      </S.Figure>
    ),
    [dream, dreamImage]
  )
}

export const DreamCardList = ({
  plansDreamsListWithImage = []
}: {
  plansDreamsListWithImage?: IPlansDreams[]
}) => {
  return useMemo(
    () => (
      <S.DreamCardList>
        {plansDreamsListWithImage
          ?.filter((dream) => dream.planoId && dream.statusSonho !== 2 && dream.statusSonho !== 3)
          ?.map((dream) => (
            <DreamCard key={dream.sonhoId} dream={dream} />
          ))}
      </S.DreamCardList>
    ),
    [plansDreamsListWithImage]
  )
}
