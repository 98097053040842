import React, { useCallback, useContext } from 'react'
import { createContext, FC } from 'react'
import {
  useGetAllEquitys,
  useGetFinancialInstitution,
  useGetManualInvestments
} from '../../queries/investments/queries'
import { useCoreContext } from './coreContext/coreProvider'
import { useOrcamentoByFamilyIdFiltro } from '../../queries/orcamento/queries'
import { useFectchCategoryByFamilyIdFiltro } from '../../queries/categoria/queries'
import { useFamilyMeiosPagamentos } from '../../queries/meioPagamento/queries'
import {
  useFetchPatrimoniosAtivosRendaFixa
  // useFetchPatrimoniosFinancialInstitutions
} from '../../queries/patrimonio/queries'
import { IInitialDataContext } from './types'

const InitialDataContext = createContext({} as IInitialDataContext)

export const InitialDataContextProvider: FC<{ children: React.ReactNode }> = ({ children }) => {
  const { usuarioId: userId, familiaId: familyId } = useCoreContext()

  const { manualInvestments, isManualInvestmentsError, isLoadingManualInvestments, manualInvestmentsError } =
    useGetManualInvestments({ userId: userId as unknown as number })

  const {
    orcamentos: orcamentosFiltro,
    isOrcamentosError: isOrcamentoFiltroError,
    isLoadingOrcamentos: isLoadingOrcamentoFiltro,
    orcamentoError: orcamentosFiltroError,
    refetchOrcamentos: refetchOrcamentoFiltro
  } = useOrcamentoByFamilyIdFiltro({ familyId: familyId as unknown as number })

  const {
    categoriasFiltro,
    isCategoriasFiltroError,
    isLoadingCategoriasFiltro,
    categoriasFiltroError,
    refetchCategoriasFiltro
  } = useFectchCategoryByFamilyIdFiltro({
    familyId: familyId as unknown as number,
    filter: {
      IncluirOrcamentos: true
    }
  })

  const { meiosPagamentos, isLoadingMeios, isErrorMeios, meiosPagamentoError, refetchMeios } =
    useFamilyMeiosPagamentos(familyId as unknown as number)

  // const {
  //   patrimoniosFinancialIntitution,
  //   errorFetchPatrimoniosFinancialInstitutions,
  //   isErrorFetchPatrimoniosFinancialInstitutions,
  //   isLoadingFetchPatrimoniosFinancialInstitutions,
  //   refetchFetchPatrimoniosFinancialInstitutions
  // } = useFetchPatrimoniosFinancialInstitutions()

  const {
    patrimoniosAtivosRendaFixa,
    errorFetchPatrimoniosAtivosRendaFixa,
    isErrorFetchPatrimoniosAtivosRendaFixa,
    isLoadingFetchPatrimoniosAtivosRendaFixa,
    refetchFetchPatrimoniosAtivosRendaFixa
  } = useFetchPatrimoniosAtivosRendaFixa({ familyId })

  const {
    financialInstitutions,
    isErrorFinancialInstituions,
    isLoadingFinancialInstituions,
    refetchGetFinancialInstituions
  } = useGetFinancialInstitution()

  const { allInvestments, isLoadingGetAllEquitys, fixedAsset, allEquitysIsFetched, manualPatrimonies } =
    useGetAllEquitys({
      familyId: familyId as number
    })

  const refetchAll = useCallback(() => {
    refetchCategoriasFiltro()
    refetchFetchPatrimoniosAtivosRendaFixa()
    // refetchFetchPatrimoniosFinancialInstitutions()
    refetchGetFinancialInstituions()
    refetchMeios()
    refetchOrcamentoFiltro()
  }, [
    refetchCategoriasFiltro,
    refetchFetchPatrimoniosAtivosRendaFixa,
    // refetchFetchPatrimoniosFinancialInstitutions,
    refetchGetFinancialInstituions,
    refetchMeios,
    refetchOrcamentoFiltro
  ])

  const isLoading = isLoadingManualInvestments || isLoadingOrcamentoFiltro || isLoadingCategoriasFiltro

  return (
    <InitialDataContext.Provider
      value={{
        allInvestments,
        fixedAsset,
        isLoadingGetAllEquitys,
        allEquitysIsFetched,

        manualInvestments,
        manualPatrimonies,
        isManualInvestmentsError,
        isLoadingManualInvestments,
        manualInvestmentsError,

        orcamentosFiltro,
        isOrcamentoFiltroError,
        isLoadingOrcamentoFiltro,
        orcamentosFiltroError,

        categoriasFiltro,
        isCategoriasFiltroError,
        isLoadingCategoriasFiltro,
        categoriasFiltroError,

        meiosPagamentos,
        isLoadingMeios,
        isErrorMeios,
        meiosPagamentoError,

        patrimoniosAtivosRendaFixa,
        errorFetchPatrimoniosAtivosRendaFixa,
        isErrorFetchPatrimoniosAtivosRendaFixa,
        isLoadingFetchPatrimoniosAtivosRendaFixa,

        // patrimoniosFinancialIntitution,
        // errorFetchPatrimoniosFinancialInstitutions,
        // isErrorFetchPatrimoniosFinancialInstitutions,
        // isLoadingFetchPatrimoniosFinancialInstitutions,

        financialInstitutions,
        isErrorFinancialInstituions,
        isLoadingFinancialInstituions,
        refetchGetFinancialInstituions,

        isLoading,
        refetchAll
      }}
    >
      {children}
    </InitialDataContext.Provider>
  )
}

export const useInitialInitialDataContext = () => {
  const context = useContext(InitialDataContext)
  if (!context) {
    throw new Error('InitialDataContext ===> Need Wrap container')
  }
  return context
}
