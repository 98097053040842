import styled from 'styled-components'
import colors from '../../../domain/Colors'
import { Loading } from '../../Icons'

export interface FamilyContainerProps {
  colorSqueme: {
    dark: string
    light: string
  }
}

export const FamilyIcon = styled.i`
  color: #fff;
  font-size: 0.9rem;
  margin: 0;
  padding: 0;
  transition: transform 0.2s ease-in-out;
  transform: translateX(0);
`

export const FamilyContainer = styled.button<FamilyContainerProps>`
  align-items: center;
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  gap: 10px;
  justify-content: space-between;
  margin: 0 auto;
  min-height: 60px;
  padding: 0;
  position: relative;
  width: 100%;

  &:after {
    content: '';
    background-color: ${({ colorSqueme }) => colorSqueme?.dark && colorSqueme?.dark};
    border-radius: 1.5rem;
    height: 100%;
    left: 0;
    position: absolute;
    width: 100%;
    z-index: 1;
    transition: opacity 0.2s ease-in-out;
  }

  &:hover,
  &:focus {
    &::after {
      opacity: 0.7;
    }

    ${FamilyIcon} {
      transform: translateX(-5px);
    }
  }
`

export const FamilyName = styled.h4`
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  color: #fff;
  display: block;
  font-size: 0.85rem;
  height: 100%;
  line-height: 1.5em;
  margin: 0;
  max-height: 3em;
  max-width: 140px;
  overflow: hidden;
  padding: 0px 0.25rem;
  position: relative;
  text-align: left;
  text-overflow: ellipsis;
  z-index: 2;
`

export const FamilyIconContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  padding: 0 10px;
  z-index: 2;
  font-size: 1rem;
`

const FamilyImageSizes = {
  width: '75px',
  height: '60px'
}
export const FamilyFigure = styled.figure`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 25%;
  height: ${FamilyImageSizes.height};
  margin: 0;
  overflow: hidden;
  position: relative;
  width: ${FamilyImageSizes.width};
  z-index: 2;

  img {
    max-height: 100%;
    max-width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    width: 100%;
  }
`

export const Loader = styled.span<{ isVisible: boolean }>`
  align-items: center;
  background: ${({ theme }) => theme.colors?.primary || colors.white};
  border-radius: 0.8rem 0.8rem 0 0;
  display: flex;
  height: ${FamilyImageSizes.height};
  justify-content: center;
  overflow: hidden;
  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
  position: absolute;
  width: ${FamilyImageSizes.width};
  z-index: 1;
`

export const LoaderIcon = styled(Loading)`
  height: 60px;
  width: 60px;
  fill: ${colors.gray};
`
