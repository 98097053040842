import { noticeError } from '../../services/Monitoring'
import { deleteWithTokenApiV2, getWithTokenApiV2, postWithTokenApiV2 } from '../../webClient'
import { IPostCreateDiagnostic, IPostCreateDiagnosticResponse } from './types'

export interface IDiagnosticSummaryResponse {
  groupName: string
  options: { id: string; name: string }[]
}

export const getDiagnosticSummaryApi = async () => {
  const { data, status } = await getWithTokenApiV2('api/FinancialDiagnostic/')

  if (status !== 200) {
    const msg = 'Error getting Diagnostic summary'
    const error = new Error(msg)
    console.error(error)
    noticeError(error)
    throw new Error(msg)
  }

  return data
}

export const postCreateDiagnosticApi = async (body: IPostCreateDiagnostic) => {
  const { data, status } = await postWithTokenApiV2<IPostCreateDiagnosticResponse, IPostCreateDiagnostic>(
    `api/FinancialDiagnostic/${body.FamilyId}`,
    body
  )

  if (status === 400) {
    const msg = data?.message || 'Erro ao criar o Diagnóstico'
    const error = new Error(msg)
    console.error(error)
    noticeError(error)
    throw error
  }

  if (status === 500) {
    const msg = 'Ocorreu um erro interno, por favor tente novamente mais tarde.'
    const error = new Error(msg)
    console.error(error)
    noticeError(error)
    throw new Error(msg)
  }

  if (status !== 200) {
    const msg = 'Erro ao criar o Diagnóstico'
    const error = new Error(msg)
    console.error(error)
    noticeError(error)
    throw new Error(msg)
  }

  return data
}

interface IDiagnosticsByFamilyId {
  financialDiagnosticId: string
  name: string
  pdfUrl: string
  status: 0 | 1
  statusDescription: 'Completed' | 'In Progress'
}
export const getDiagnosticsByFamilyIdApi = async ({ familyId }: { familyId?: number }) => {
  if (!familyId) {
    console.error('Error getting Diagnostics by familyId: familyId is required')
    return
  }
  const { data, status } = await getWithTokenApiV2<IDiagnosticsByFamilyId[]>(
    `api/FinancialDiagnostic/${familyId}`
  )

  if (status !== 200) {
    const msg = 'Error getting Diagnostics by familyId'
    const error = new Error(msg)
    console.error(error)
    noticeError(error)
    throw new Error(msg)
  }

  return data
}

export const getDiagnosticsByIdApi = async ({ diagnosticId }: { diagnosticId?: string }) => {
  if (!diagnosticId) {
    console.error('Error getting Diagnostics by familyId: familyId is required')
    return
  }
  const { data, status } = await getWithTokenApiV2<IDiagnosticsByFamilyId>(
    `api/FinancialDiagnostic/Byid/${diagnosticId}`
  )

  if (status !== 200) {
    const msg = `Error getting Diagnostic by diagnosticId: ${diagnosticId}`
    const error = new Error(msg)
    console.error(error)
    noticeError(error)
    throw new Error(msg)
  }

  return data
}

// TODO: REFACTOR
export const deleteDiagnosticsByFamilyIdApi = async ({
  financialDiagnosticId
}: {
  financialDiagnosticId?: string
}) => {
  if (!financialDiagnosticId) {
    console.error('Error getting Diagnostics by financialDiagnosticId: financialDiagnosticId is required')
    return
  }
  const { data, status } = await deleteWithTokenApiV2(`api/FinancialDiagnostic/${financialDiagnosticId}`)

  if (status !== 200) {
    const msg = 'Error getting Diagnostics by familyId'
    const error = new Error(msg)
    console.error(error)
    noticeError(error)
    throw new Error(msg)
  }

  return data
}
