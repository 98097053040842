import styled, { css } from 'styled-components'
import { Content, ModalV2 as Modal, ButtonCreate } from '../styles'
import colors from '../../../../domain/Colors'
import { FormGroup as MuiFormGroup, FormControlLabel as FormControlLabelMui } from '@material-ui/core'
// import { TextareaWithFormatting } from '../../../Inputs/TextArea'

export const ModalV2 = styled(Modal)``

export { ButtonCreate }

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  /* padding: 1rem; */
`

export const Fieldset = styled.fieldset`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding: 1rem;
`

export const FormTitle = styled.h3`
  font-family: 'Open Sans';
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.625;
`

export const FormSubtitle = styled(FormTitle)`
  font-weight: normal;
  font-size: 0%.9rem;
`

export const FormSection = styled.section`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`

export const Table = styled.table`
  display: flex;
  flex-direction: column;
  margin-bottom: 1.8rem;
  border-radius: 0.7rem;
  overflow: hidden;
  width: 100%;

  tr {
    display: inline-table;
    width: 100%;
  }

  td,
  th {
    padding: 0.5rem 0.8rem;
    width: 33%;
  }

  thead,
  tfoot {
    tr {
      background-color: ${colors.grayBlue};
    }
  }

  tbody {
    tr {
      background-color: #ecf0f6;
    }

    td {
      border: none;
      border-bottom: 1px solid #dddddd;
      border-top: 1px solid #dddddd;
    }
    &:last-of-type {
      td {
        border-bottom: none;
      }
    }
  }
`

export const Disclaimer = styled.td`
  text-align: left;
  font-size: 0.7rem;
  color: gray;
`

export const FormGroup = styled(MuiFormGroup)<{ padding?: boolean }>`
  max-height: 250px;
  overflow-x: auto;

  ${({ padding }) =>
    padding &&
    css`
      padding: 0 1rem;
    `}
`

export const FormControlLabel = styled(FormControlLabelMui)`
  .MuiIconButton-label {
    display: flex !important;
  }

  .MuiFormControlLabel-label.MuiTypography-body1 {
    font-size: 0.9rem;
    line-height: 1;
  }

  .MuiCheckbox-colorSecondary.Mui-checked {
    color: ${({ theme }) => theme.colors.primary};
  }
`

export const SubmitingOverlay = styled.div<{ isVisible: boolean }>`
  align-items: center;
  background-color: rgba(255, 255, 255, 0.8);
  bottom: 0;
  display: ${({ isVisible }) => (isVisible ? 'flex' : 'none')};
  justify-content: center;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 2;
`

export const TextArea = styled.textarea`
  border: 1px solid ${colors.grayBlue};
  border-radius: 0.5rem;
  padding: 1rem;
  height: 140px;
  resize: none;
`

export const Error = styled.span`
  color: red;
  font-size: 0.8rem;
`

export const LengthContainer = styled.span`
  display: flex;
`

export { Content }

export const Counter = styled.div`
  align-items: center;
  display: flex;
  font-size: 0.8rem;
  justify-content: space-between;
`

export const HiddenButton = styled.button`
  display: none;
`
