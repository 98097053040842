export function adjustBrightness(hexColor, percent) {
  // Remove o símbolo '#' se existir
  const color = hexColor.replace('#', '')
  // Divide o hexadecimal em componentes RGB
  const r = parseInt(color.substring(0, 2), 16)
  const g = parseInt(color.substring(2, 4), 16)
  const b = parseInt(color.substring(4, 6), 16)

  // Ajusta cada componente
  const adjust = (component) =>
    Math.min(255, Math.max(0, Math.round(component + (component * percent) / 100)))

  const newR = adjust(r)
  const newG = adjust(g)
  const newB = adjust(b)

  // Converte de volta para hexadecimal
  const toHex = (component) => component.toString(16).padStart(2, '0')

  return `#${toHex(newR)}${toHex(newG)}${toHex(newB)}`
}

export function getLighterAndDarker(hexColor, adjustment = 20) {
  const lighter = adjustBrightness(hexColor, adjustment) // Mais claro
  const darker = adjustBrightness(hexColor, -adjustment) // Mais escuro

  return { lighter, darker }
}

export const getLighter = (lighterColor, colorSupport, adjustment = 80) => {
  if (!lighterColor) {
    return adjustBrightness(colorSupport, adjustment)
  }
  return lighterColor
}

export const getDarker = (darkerColor, colorSupport, adjustment = -60) => {
  if (!darkerColor) {
    return adjustBrightness(colorSupport, adjustment)
  }
  return darkerColor
}
