import React from 'react'
import MUIDataTable from 'mui-datatables'
import CurrencyInput from 'react-currency-input'
import axios from 'axios'
import MaskedInput from 'react-maskedinput'
import { registerLocale } from 'react-datepicker'
import br from 'date-fns/locale/pt-BR'
import { createTheme, MuiThemeProvider } from '@material-ui/core/styles'
import WrapperForCurrency from '../../../../theme/wrapperForCurrency'
import WrapperForDatePicker from '../../../../theme/wrapperForDatePicker'
import { withContexts } from '../../../../components'

const theme = createTheme({
  overrides: {
    MuiTableRow: {
      root: {
        '&:nth-of-type(odd)': {
          backgroundColor: '#f8f9fe'
        },
        '& input': {
          background: 'transparent'
        }
      }
    },
    MuiTableCell: {
      root: {
        fontFamily: 'unset',
        borderTop: 'none',
        padding: '16px 10px'
      }
    },
    MUIDataTableHeadCell: {
      root: {
        fontWeight: 700
      }
    },
    MuiPaper: {
      root: {
        overflow: 'hidden',
        margin: 0
      },
      elevation4: {
        boxShadow: 'none',
        border: '0.5px solid rgb(228, 234, 244)'
      }
    },
    MUIDataTableToolbar: {
      left: {
        margin: 10
      }
    },
    MuiToolbar: {
      regular: {
        marginTop: 16
      }
    }
  }
})

registerLocale('br', br)

class Geral extends React.Component {
  state = {
    familiaId: this.props.familiaId,
    data: []
  }
  moment = require('moment')

  componentDidMount() {
    var $this = this

    this.setState(
      {
        familiaId: this.props.familiaId
      },
      $this.refreshData()
    )
  }

  getData = () => {
    //var url = global.server_api + "api/EquilibrioFinanceiro/familia/"+this.state.familiaId+"/tipoOrcamento";
    var url =
      global.server_api_new + '/EquilibrioFinanceiro/familia/' + this.state.familiaId + '/tipoOrcamento'

    var filtro = {
      DataInicial: this.state.startDate,
      DataFinal: this.state.endDate
    }

    axios.post(url, filtro).then((res) => {
      var data = []

      var totalMeta = 0
      var totalAtual = 0

      if (res.data.results != null) {
        res.data.results.forEach((item) => {
          data.push({
            id: item.id,
            tipoOrcamento: item.tipoOrcamento,
            nome: item.nome,
            mesAtual: item.mesAtual,
            meta: item.meta,
            data: item.mes != null && item.ano != null ? item.mes + '/' + item.ano : '',
            ehTotal: 0
          })

          if (item.tipoOrcamento === 0) {
            totalAtual += item.mesAtual
            totalMeta += item.meta
          } else {
            totalAtual -= item.mesAtual
            totalMeta -= item.meta
          }
        })

        data.push({
          nome: 'Total',
          mesAtual: totalAtual,
          meta: totalMeta,
          ehTotal: 1
        })
      }

      this.setState({ data })
    })
  }

  setStartDate = (date, callback) => {
    if (date != null) {
      date.setHours(0)
      date.setMinutes(0, 0, 0)
      this.props.setDataInicio(this.moment(date).format('YYYY-MM-DDTHH:mm:ss'))
    }

    const startDate = date

    this.setState({ startDate }, function () {
      if (callback != null) {
        callback()
      } else {
        this.getData()
      }
    })
  }

  setEndDate = (date, callback) => {
    if (date != null) {
      date.setHours(23)
      date.setMinutes(59, 59, 59)
      this.props.setDataFim(this.moment(date).format('YYYY-MM-DDTHH:mm:ss'))
    }

    const endDate = date

    this.setState({ endDate }, function () {
      if (callback != null) {
        callback()
      } else {
        this.getData()
      }
    })
  }

  columns = [
    {
      name: 'ehTotal',
      options: {
        display: false,
        viewColumns: false,
        filter: false
      }
    },
    {
      name: 'id',
      options: {
        display: false,
        viewColumns: false,
        filter: false
      }
    },
    {
      name: 'tipoOrcamento',
      options: {
        display: false,
        viewColumns: false,
        filter: false
      }
    },
    {
      name: 'nome',
      label: 'Nome',
      options: {
        display: true,
        viewColumns: true,
        filter: false
      }
    },
    {
      name: 'mesAtual',
      label: 'Realizado',
      options: {
        display: true,
        viewColumns: true,
        filter: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          if (value == null) value = 0

          return (
            <div>
              {value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }).replace('R$', '')}
            </div>
          )
        }
      }
    },
    {
      name: 'meta',
      label: 'Meta',
      options: {
        display: true,
        viewColumns: true,
        filter: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          if (value == null) value = 0

          if (tableMeta.rowData[0] === 0) {
            return (
              <div>
                <WrapperForCurrency>
                  <CurrencyInput
                    value={value}
                    className="inputCurrency"
                    thousandSeparator={'.'}
                    precision="2"
                    onChangeEvent={(event, formattedValue, value) => {
                      updateValue(value)

                      if (this.state.timeout != null) {
                        clearTimeout(this.state.timeout)
                      }

                      var $this = this

                      var timeout = setTimeout(function () {
                        var tipoOrcamento = tableMeta.rowData[2]
                        //var service = 'api/EquilibrioFinanceiro/familia/' +$this.state.familiaId+ '/tipoOrcamento/'+tipoOrcamento;
                        var service = `/EquilibrioFinanceiro/familia/${this.props.familiaId}/tipoOrcamento/${tipoOrcamento}`
                        var url = global.server_api_new + service

                        var data = null

                        if (
                          tableMeta.rowData[6] != null &&
                          tableMeta.rowData[6].indexOf('/') === -1 &&
                          tableMeta.rowData[6].length === 6
                        ) {
                          data = '01' + tableMeta.rowData[6]
                          data = data.substr(0, 2) + '/' + data.substr(2, 2) + '/' + data.substr(4, 4)
                        } else if (
                          tableMeta.rowData[6] != null &&
                          tableMeta.rowData[6].indexOf('/') >= 0 &&
                          tableMeta.rowData[6].length === 7
                        ) {
                          data = '01/' + tableMeta.rowData[6]
                        }

                        data = data != null ? $this.moment(data, 'DD/MM/YYYY').toDate() : null

                        var dados = {
                          meta: value,
                          data: data
                        }

                        axios.post(url, dados).then((res) => {
                          if (res.data.success) {
                            $this.getData()
                          }
                        })
                      }, 1000)

                      this.setState({ timeout })
                    }}
                  />
                </WrapperForCurrency>
              </div>
            )
          } else {
            return (
              <>{value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }).replace('R$', '')}</>
            )
          }
        }
      }
    },
    {
      name: 'data',
      label: 'Data da Meta',
      options: {
        display: true,
        viewColumns: true,
        filter: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          var $this = this

          if (value == null) value = ''

          if (tableMeta.rowData[0] === 1) {
            return <></>
          }

          return (
            <div>
              <WrapperForDatePicker>
                <MaskedInput
                  id="inputMasked"
                  className="inputCurrency"
                  mask={'11/1111'}
                  value={value}
                  onChange={(e) => {
                    updateValue(e.target.value)

                    var novoValor = e.target.value
                    var data = null

                    // console.log('novadata antes', novoValor);

                    if (novoValor == null || novoValor.indexOf('_') > -1) {
                      return
                    }

                    if (novoValor != null && novoValor.indexOf('/') === -1 && novoValor.length === 6) {
                      data = '01' + novoValor
                      data = data.substr(0, 2) + '/' + data.substr(2, 2) + '/' + data.substr(4, 4)
                    } else if (novoValor != null && novoValor.indexOf('/') >= 0 && novoValor.length === 7) {
                      data = '01/' + novoValor
                    }

                    // console.log('novadata depois', data);

                    if (data != null) {
                      if (this.state.timeout != null) {
                        clearTimeout(this.state.timeout)
                      }

                      var timeout = setTimeout(function () {
                        var id = tableMeta.rowData[2]
                        //var service = 'api/EquilibrioFinanceiro/familia/' +$this.state.familiaId+ '/tipoOrcamento/'+id;
                        var service = `/EquilibrioFinanceiro/familia/${this.props.familiaId}/tipoOrcamento/${id}`

                        var url = global.server_api_new + service

                        data = data != null ? $this.moment(data, 'DD/MM/YYYY').toDate() : null

                        var dados = {
                          meta:
                            tableMeta.rowData[5] != null && tableMeta.rowData[5] !== ''
                              ? tableMeta.rowData[5]
                              : null,
                          data: data
                        }

                        axios.post(url, dados).then((res) => {
                          if (res.data.success) {
                            $this.getData()
                          }
                        })
                      }, 1000)

                      this.setState({ timeout })
                    }
                  }}
                />
              </WrapperForDatePicker>
            </div>
          )
        }
      }
    }
  ]

  options = {
    filter: true,
    filterType: 'dropdown',
    responsive: 'stacked',
    print: false,
    download: false,
    sort: true,
    pagination: false,
    selectableRows: 'none',
    textLabels: global.textLabels
  }

  refreshData = () => {
    var $this = this

    var startDate = this.props.dataInicio
    var endDate = this.props.dataFim

    this.setStartDate(startDate, function () {
      $this.setEndDate(endDate, function () {
        $this.getData()
      })
    })
  }

  render() {
    return (
      <>
        <button
          id="geral"
          onClick={() => this.refreshData()}
          style={{ position: 'fixed', left: '-1000px' }}
        />
        <MuiThemeProvider theme={theme}>
          <MUIDataTable data={this.state.data} columns={this.columns} options={this.options} />
        </MuiThemeProvider>
      </>
    )
  }
}

export default withContexts(Geral)
