import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
import { useLocation } from 'react-router-dom'

const ReCaptchaConditionalProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const location = useLocation()
  const recaptchaKey = process.env.REACT_APP_RECAPTCHA_KEY || '6LfXA2MqAAAAAJbw6mrANH_aHs4Q67BHKBm8bt7a'

  const reCaptchaEnabledRoutes = ['/auth/login', '/auth/reset', '/admin/alterar-senha']

  const isReCaptchaEnabled = reCaptchaEnabledRoutes.some((route) => location.pathname.endsWith(route))

  return (
    <GoogleReCaptchaProvider reCaptchaKey={recaptchaKey}>
      <style>
        {`
          .grecaptcha-badge {
            width: 70px !important;
            overflow: hidden !important;
            transition: none !important;
            position: fixed !important;
            bottom: 15px !important;
            left: 15px !important;
            display: ${isReCaptchaEnabled ? 'block' : 'none'} !important;
          }
        `}
      </style>
      {children}
    </GoogleReCaptchaProvider>
  )
}

export default ReCaptchaConditionalProvider
