import React, { useCallback, useEffect, useState } from 'react'
import { ButtonSaveTransaction } from '../../Button/Button'
import { Summary } from './summary'
import { useTransactionContext } from '../../../../../../hooks/contexts/transactionContext'
import { useTheme } from '../../../../../../hooks'
import { ErrorTransaction } from '../../../../../../hooks/contexts/transactionContext/errorTransaction'
import { SuccessTransaction } from '../../../../../../hooks/contexts/transactionContext/successTransaction'
import { Transaction } from '../types'

export const useSaveTransactionsActions = () => {
  const {
    setIsOpenEditing,
    isEditing,
    isOpenEditing,
    isSubmitting,
    error,
    saveTransaction,
    transaction,
    setShowError,
    showError,
    setShowSuccess,
    onSuccessClose,
    showSuccess,
    setTransaction,
    modal
  } = useTransactionContext()
  const [success, setSuccess] = useState(false)
  const { theme } = useTheme()
  const { id } = transaction || {}
  const editing = !!id

  const handleSave = useCallback(
    (lancamento?: Transaction) => {
      if (!transaction) {
        console.error('[useSaveTransactionsActions:49] Transaction not found')
        return
      }

      if (editing) {
        setIsOpenEditing(true)
        return
      }

      saveTransaction({
        transaction: lancamento
      })
    },
    [editing, saveTransaction, setIsOpenEditing, transaction]
  )

  const SaveButton = useCallback(
    ({
      disabled,
      beforeSave,
      transaction: lancamento
    }: {
      disabled?: boolean
      beforeSave?: () => void
      transaction?: Transaction
    }) => {
      return (
        <ButtonSaveTransaction
          disabled={disabled || !lancamento}
          isEditing={editing}
          isSubmitting={isSubmitting}
          onClick={() => {
            if (!lancamento) {
              console.error('[useSaveTransactionsActions:78] Transaction not found')
              return
            }
            if (beforeSave) {
              beforeSave()
              setTimeout(() => handleSave(lancamento), 350)
              return
            }

            handleSave(lancamento)
          }}
          theme={theme}
        />
      )
    },
    [editing, handleSave, isSubmitting, theme]
  )

  const EditingModals = useCallback(() => {
    return (
      <>
        {editing && isOpenEditing && (
          <Summary
            tipoOrcamento={transaction?.tipoOrcamento}
            open={isOpenEditing}
            onError={() => setShowError(true)}
            editingTransaction={editing}
            onSuccess={() => {
              modal.close()
              setIsOpenEditing(false)
              setShowSuccess(true)
            }}
            onClose={() => {
              setIsOpenEditing(false)
            }}
          />
        )}

        {showError && (
          <ErrorTransaction
            onClose={() => setShowError(false)}
            editingTransaction={isEditing}
            message={error}
          />
        )}

        <SuccessTransaction
          onClose={() => {
            setShowSuccess(false)
            onSuccessClose()
            setSuccess(false)
            setTransaction(undefined)
            const root = document.querySelector('html')
            if (root) root.style.overflow = 'unset'
          }}
          open={success}
          editingTransaction={isEditing}
        />
      </>
    )
  }, [
    editing,
    error,
    isEditing,
    isOpenEditing,
    modal,
    onSuccessClose,
    setIsOpenEditing,
    setShowError,
    setShowSuccess,
    setTransaction,
    showError,
    success,
    transaction
  ])

  useEffect(() => {
    if (showSuccess) {
      setSuccess(true)
    }
  }, [showSuccess, success])

  return {
    onReset: () => {},
    EditingModals,
    SaveButton
  }
}
